import React, { useState, useEffect } from "react";
import Course from "./Course";
import { useCookies } from "react-cookie";
import EnrollmentService from "../../services/EnrollementService";


const TutorEnrollments =()=> {
    const [ enrollments, setEnrollments ] = useState([]);
    // const [request, setRequest] = useState(null)
    const [cookies, setCookies] = useCookies(['user']);

    const {fetchTutorEnrollments} = EnrollmentService();
    // console.log(userType)

    

    function updateEnrollmentElement(resp){
        console.log(resp)
        if(resp !=null ){
            return resp.then((res) => res.json())
            .then((data) => {
                let actualData = (data.data);
                
                setEnrollments(actualData);
                console.log(actualData)
            })
            .catch(err=>{
                console.log(err)
                // alert(err)
            })
        }  
    }

    useEffect(() => {
        // setRequest(fetchCourse(cookies?.user?.token, 'tutor', 10))
        updateEnrollmentElement(fetchTutorEnrollments(cookies?.user?.token))
        // updateCourseElement(request)
    }, [])
    return(
        
            <div className="table-responsive-sm p-0 ">
                <table className="table text-center">
                    <thead>
                    <tr className="text-center custom-tr">
                        <th scope="col">Course Title</th>
                        <th scope="col">Status</th>
                        <th scope="col">Classroom Link</th>
                        <th scope="col">Timetable</th>
                    </tr>
                    </thead>
                    <tbody>
                    {enrollments?.map((enrollment, index) => (

// img = {window.$base_url+'/storage/'+enrollment.cover_photo}
// title = {enrollment.title}
// duration = {enrollment.duration}
// ratings = {enrollment.ratings}
// cost = {enrollment.original_price}
// span = {enrollment.discounted_price}
// slug = {enrollment.slug}
// userType = {userType} 
// key = {enrollment.id}
                        <tr className="text-center" key={index}>
                            <th scope="row">{enrollment?.course?.title}</th>
                            <td><span className={`badge bg-${enrollment.status.color}`}>{enrollment.status.statement}</span> </td>
                            <td><a href="https://us.zoom.classrom/stormcell-class.com" target="_blank">https://us.zoom.classrom/stormcell-class.com</a></td>
                            <td>
                                <div className="row mt-3    ">
                                    <div className="col-4">
                                        <a href="">
                                        <span className="custom-icon-div p-2">
                                            <i className="bi bi-eye text-white"></i>
                                        </span>
                                        </a>
                                    </div>
                                    <div className="col-4">
                                        <a href="">
                                        <span className="custom-icon-div1 p-2">
                                            <i className="bi bi-download text-white "></i>
                                        </span>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            
    )
}

export default TutorEnrollments;