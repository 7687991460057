export function closeModal(modalId){
    document.querySelector("#close-"+modalId).click();
}

export function triggerLoad(target){
    console.log(target);
    const oldHtml = target.innerHTML;
    target.setAttribute('disabled', true);
    
    target.innerHTML = '<i class="fa fa-spin fa-spinner"></i>';
    return oldHtml;
}

export function stopLoading(target, html){
    target.innerHTML = html;
    target.removeAttribute("disabled");
    return;
}