import {useEffect, useState} from "react";
import Courses from "../includes/Courses";
import Footer from "../includes/Footer";
import Navbar from "../includes/Navbar";
import CourseService from "../../services/CourseService";

const CoursePage=()=>{

    const [categories, setCategories] = useState([]);
    const {fetchCourseCategories} = CourseService();

    const getCourseService = (resp)=>{
        let results = [];
        if(resp !=null ){
            return resp.then((res) => res.json())
            .then((data) => {
                let actualData = (data.data);
                setCategories(actualData)
            })
            .catch(err=>{
                console.log(err)
            })
        } 
    }
    useEffect(()=>{
        getCourseService(fetchCourseCategories());
    }, [])
    return(
        <>
        <Navbar />
        <div className="course py-5">
            <div className="serve pt-5 mb-5">
                <div className="container text-center py-3">
                        <h2>Take a Bold Action, Choose your Course!</h2>
                    <p className="text-dark">
                        Get on with the positivity.<br />This will take you to success.</p>
                </div>
            </div>
            <Courses userType='learner'/>
            {
                categories.map((category, index)=>(
                    <Courses userType='learner' category={category.slug} title={category.name}/>
                ))
            }
            
            {/* <Courses userType='learner' category={"programming_and_web_development"}/> */}

        </div>

        <Footer />
        </>
    );
}

export default CoursePage;