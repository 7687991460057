import React, { useEffect, useState } from "react";
import $ from "jquery";

const CustomAlert = (props) => {
    // console.log(props.data)
    const [data, setData] = useState(props.data);

    const closeAlert = (ev)=>{
       setData({
        color:''
       });
    //    ev.target.style.display = 'none'
       
    }

    useEffect(()=>{
        // alert('hey')
        setData(props.data);
        // console.log(data)
    }, [props])
    
   
    return ( 
        
        <div className="row">
            {data.color != '' &&
                <div className="col-md-6 offset-md-3 alert-parent">
                    <div className={`alert alert-${data.color} alert-dismissible fade show`} role="alert"  id="alert">
                        <strong>{data.title}</strong> {data.message}
                        <button type="button" className="btn-close" onClick={closeAlert}></button>
                    </div>
                </div>
            }
            
        </div> 
        

        // <div className=" col-md-6 offset-md-3">
        //     {props.data.color !== "" &&  <Alert 
        //         title={props.data.title}
        //         message={props.data.message}
        //         color={props.data.color}
        //     /> }
        // </div>
    );
}
 
export default CustomAlert;