import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import $ from 'jquery'

const SideNav = () => {

    const navigate = useNavigate();

    const Logout = ()=>{
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
        navigate('/login')
    }

    useEffect(()=>{
        let screenWidth = $( window ).width();
        if(screenWidth>760){
            $('.side-nav .side-link').hide();
            $('.side-nav').css('width', '70px');
            $('.logo').hide()
            $('.close-side-bar').hide()
        }else{
            $('.side-nav').hide()
        }

        $('.nav-menu-btn, .nav-menu-mobile-btn').on('click', function(){

            let screenWidth = $( window ).width();
                // console.log(screenWidth);
            if(screenWidth>760){
                    $('.nav-menu-btn').hide();
                    $('.side-nav .side-link').show();
                    $('.side-nav').css('width', '260px');
                    $('.logo').show()
                    $('.close-side-bar').show()
            }
            else{
                    $('.side-nav').show();
                    $('.nav-menu-mobile-btn').hide();
                    $('.nav-menu-btn').hide();
                    $('.side-nav .side-link').show();
                    $('.side-nav').css('width', '260px');
                    $('.logo').show()
                    $('.close-side-bar').show()
                    
            }
            
        })

        $('.close-side-bar').on('click', function(){
            let screenWidth = $( window ).width();
            if(screenWidth>760){
                $('.side-nav .side-link').hide();
                $('.side-nav').css('width', '70px');
                $('.logo').hide()
                $('.close-side-bar').hide()
                $('.nav-menu-btn').show();
            }else{
                $('.nav-menu-mobile-btn').show();
                // $('.side-nav .side-link').hide();
                $('.side-nav').hide();
            }
        })

        $(window).on('resize', function() {
                if ($(this).width() > 760) {
                    $('.nav-menu-btn').show();
                    $('.side-nav .side-link').hide();
                    $('.side-nav').show()
                    $('.side-nav').css('width', '70px');
                    $('.logo').hide()
                    $('.close-side-bar').hide()
                    
                }else{
                    $('.side-nav').hide();
                }
        });
    }, [])
    
    return ( 
        <>
         <div className="card custom-upload-card side-nav">
            <div className="d-flex justify-content-center pt-4">
                <Link className="navbar-brand " to="/">
                    <img src="/assets/images/logo/stormcell-logo-vect.svg" className="logo stormcell-logo" alt="" />
                </Link>
                <span style={{cursor: 'pointer'}} className="close-side-bar mt-1"><i className="fa fa-close"></i></span>
                <span className="nav-menu-btn"><i className="fa fa-bars"></i></span>
                
            </div>
            <hr />
        
            <div className="bos custom-upload-card">
            
                <div className="bord custom-bord bg-white  p-3  side-bar-item">
                    <Link to="/dashboard">
                        <i className="bi bi-house mx-3" style={{color: '#041066'}} ></i>
                        <span className="side-link">Dashboard</span>
                    </Link>
                </div>
            

                <div className="bord p-3 bg-white">
                    <div className="custom-bord side-bar-item" data-bs-toggle="collapse" to="#collapseExample" 
                            role="button"      aria-expanded="false" aria-controls="collapseExample">
                        <Link to="/courses/enrolled"> 
                            <i 
                                className="bi bi-book mx-3" 
                                style={{color:'#041066'}} 
                                data-bs-toggle="tooltip" data-bs-placement="top"
                                data-bs-custom-class="custom-tooltip"
                                data-bs-title="This top tooltip is themed via CSS variables."
                            ></i>
                            <span className="side-link">Enrolled Courses </span>
                        </Link>
                    </div >
                </div>

                <div className="bord p-3 bg-white">
                    <Link to="/payment-history">
                        <div className="custom-bord side-bar-item" data-bs-toggle="collapse" to="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample">
                            <i className="bi bi-card-checklist mx-3" style={{color: '#041066'}} ></i>
                            <span className="side-link">Payment History</span>
                        </div >
                    </Link>
                </div>
                
                {/* <span className=" dropdown-item" >Logout</span> */}
               
                    <div className="bord custom-bord bg-white p-3 ">
                        <i className="bi bi-box-arrow-left mx-3" style={{color:'#041066'}} ></i>
                        <span className="side-link"onClick={Logout} style={{ cursor:"pointer" }}>Logout</span>
                    </div>
                
            
            </div>
         </div>
        </>
     );
}
 
export default SideNav;