import React, { useState, useEffect } from "react";
import Course from "./Course";
import { useCookies } from "react-cookie";
import CourseService from "../../services/CourseService";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const Courses =({userType, items, category, title})=> {
    const [ courses, setCourses ] = useState([]);
    const [cookies, setCookies] = useCookies(['user']);
    const [slideItems, setSlideItems] = useState([]);
   
    const handleDragStart = (e) => e.preventDefault();

    const determineItemCount = (width)=>{
        return Math.floor(width /240) >= 2 ? Math.floor(width /240): 2;
    }
    const responsive = {
        0: { items: 1 },
        359: { items: determineItemCount(360) },
        768: { items: determineItemCount(768) },
        992: { items:  determineItemCount(992) },
        1200: { items:  determineItemCount(1200) }
    };
    
    const {fetchCourse} = CourseService();
   
    function updateCourseElement(resp){
        let results = [];
        if(resp !=null ){
            return resp.then((res) => res.json())
            .then((data) => {
                let actualData = (data.data.data);
                actualData.forEach(function(item){
                    results.push(
                        <Course 
                            img = {window.$base_url+'/storage/'+item.cover_photo}
                            title = {item.title}
                            duration = {item.duration}
                            ratings = {item.ratings}
                            cost = {item.original_price}
                            span = {item.discounted_price}
                            slug = {item.slug}
                            userType = {userType} 
                            key = {item.id}
                        />  
                      
                    )
                })
                setSlideItems(results);
                setCourses(actualData);
            })
            .catch(err=>{
                console.log(err)
            })
        }  
    }

    useEffect(() => {
        // setRequest(fetchCourse(cookies?.user?.token, userType, items, category))
        updateCourseElement(fetchCourse(cookies?.user?.token, userType, items, category))
    }, [])


   
    return(
        <>
            <div className="container bg-white py-3 mb-3">
                {category !=null ?

                    <h4 className="course-categories-heading mb-3">Explore courses on <span>{title}</span> </h4>
                :
                    <h4 className="course-categories-heading mb-3">Explore courses accross all categories</h4>
                }
                <div className="flex">
                    <AliceCarousel
                        mouseTracking
                        items={slideItems}
                        responsive={responsive}
                        controlsStrategy="alternate"
                        infinite={true}
                    />  
                </div>
                    
            </div>
         </>
    );
}

export default Courses;