import React, { useState, useEffect, useRef } from "react";

import { useParams, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import CustomAlert from "./CustomAlert";
import { closeModal, triggerLoad, stopLoading } from "../../utils/generic";

const Management = () => {
  

  const [course, setCourse] = useState({
    topics: [],
    objectives: [],
  });

  const [intro_video_link, setVideo] = useState();

  const { slug } = useParams();

  const [cookies, setCookies] = useCookies(["user"]);

  const [show, setShow] = useState(false);

  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    color: "",
  });

  const [newTopic, setNewTopic] = useState("");

  const [newObective, setNewObjective] = useState("");

  const topicInputRef = useRef();
  const objectiveInputRef = useRef();

  async function fetchCourse() {

    return fetch(window.$base_url+ "/api/courses/" + slug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
      },
      body: JSON.stringify(),
    })
    .then((res) => res.json())
    .then((data) => {
      let actualData = data.data;

      setCourse(actualData);
      console.log(actualData);
    })
    .catch((err) => {
      alert("error");
    });
  }

  function updateNewTopic(event) {
    const nTopic = event.target.value;
    setNewTopic(nTopic);
  }

 

  async function submitTopic() {
    return fetch(window.$base_url+ "/api/courses/add-topic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        slug: slug,
        topic: newTopic,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if ((data.status = "success")) {
          topicInputRef.current.value = '';
          let newCourse = course;
          newCourse.topics = data.topics;
          setCourse(newCourse);
          setAlertData({
            title: "Success!",
            message: data.message,
            color: "success",
          });
        } else {
          setAlertData({
            title: data.message,
            message: data.error,
            color: "danger",
          });
        }
        closeModal("addTopicModal");
      })
      .catch((err) => {
        alert("error");
      });
  }



  useEffect(() => {
    fetchCourse();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const courses = await fetch(window.$base_url+ "/api/courses/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        slug: slug,
        fields: {
          intro_video_link,
        },
      }),
    });

    const feedback = await courses.json();
    alert(feedback.message);
  };

  const submitObjective = async () => {
    return fetch(window.$base_url+ "/api/courses/add-objective", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        slug: slug,
        objective: newObective,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == "success") {
          objectiveInputRef.current.value = ''
          objectiveInputRef.current.value = '';
          let newCourse = course;
          newCourse.objectives = data.objectives;
          setCourse(newCourse);
          setAlertData({
            title: "Success!",
            message: data.message,
            color: "success",
          });
        } else {
          setAlertData({
            title: data.message,
            message: data.error,
            color: "danger",
          });
        }
        closeModal("addObjectiveModal");
      })

      .catch((err) => {
        alert("error");
      });
  };

  const updateNewObjective = (event) => {
    const nObjective = event.target.value;
    setNewObjective(nObjective);
  };

  const updateObjective = async (event) => {
    event.preventDefault();
    const form = event.target;
    
    const objectiveId = form.getAttribute('objective-id');
   

    closeModal("edit-objective-modal-"+objectiveId);

    const objectiveIndex = form.getAttribute('objective-index');
    
    const objective = course.objectives[objectiveIndex].objective
    
    // console.log(objectiveId);
    // return;

    return fetch(window.$base_url+ "/api/courses/update-objective", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        "id": objectiveId,
        "objective": objective
      }),
    })
    .then((res) => res.json())
    .then((data) => {
      if(data.status == 'success'){
        setAlertData({
          title: "Congrats!",
          message: data.message,
          color: "success",
        })
      }else{
        setAlertData({
          title: "Oops!",
          message: data.error,
          color: "danger",
        })
      }
    })

    .catch((err) => {
      alert("error");
    });

     
  };

  async function updateCourseStatus() {
    return fetch(window.$base_url+ "/api/courses/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        slug: slug,
        fields: {
          status: 0,
        },
      }),
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.status == "success") {
          setAlertData({
            title: "Success!",
            message: data.message,
            color: "success",
          });
        } else {
          setAlertData({
            title: data.message,
            message: data.error,
            color: "danger",
          });
        }
        console.log(data);
        // closeModal("addTopicModal");
      })
      .catch((err) => {
        alert("error");
      });
  }


 
  const updateTopic = async (event) => {
    event.preventDefault();
    const form = event.target;
    
    const topicSlug = form.getAttribute('topic-slug');
    
    const topicIndex = form.getAttribute('topic-index');
   
    const detail = course.topics[topicIndex].detail;
    // console.log( detail);
    // return;
    const resources = course.topics[topicIndex].external_resources
    // const topicEditIndex =  form.getAttribute('topicEdit-index')
    const topic = course.topics[topicIndex].topic


    return fetch(window.$base_url+ "/api/courses/add-topic-detail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        "slug": topicSlug,
        "detail": detail,
        "resources": resources,
        "topic": topic
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.status == 'success'){
          setAlertData({
            title: "Congrats!",
            message: data.message,
            color: "success",
          })
        }else{
          setAlertData({
            title: "Oops!",
            message: data.error,
            color: "danger",
          })
        }
      })

      .catch((err) => {
        alert("error");
      });

     
  };

  const deleteTopic = async (event) => {
    event.preventDefault()
    const form = event.target;
    const deleteSlug = form.getAttribute('delete-index');
    return fetch(window.$base_url+ "/api/courses/delete-topic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        "slug": deleteSlug,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == "success") {
          console.log(data);
          let newCourse = course;
          newCourse.topics = data.topics;
          setCourse(newCourse);
          setAlertData({
            title: "Success!",
            message: data.message,
            color: "success",
          });
        } else {
          setAlertData({
            title: data.message,
            message: data.error,
            color: "danger",
          });
        }
       
      })

      .catch((err) => {
        alert("error");
      });

     
  };

  const deleteObjective = async (event) => {
    event.preventDefault()
    const form = event.target;
    const deleteId = form.getAttribute('delete-index');
    console.log(deleteId);
    // return;
    return fetch(window.$base_url+ "/api/courses/delete-objective", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        "id": deleteId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == "success") {
          let newCourse = course;
          newCourse.objectives = data.objectives;
          setCourse(newCourse);
          setAlertData({
            title: "Success!",
            message: data.message,
            color: "success",
          });
        } else {
          setAlertData({
            title: data.message,
            message: data.error,
            color: "danger",
          });
        }
       
      })

      .catch((err) => {
        alert(err);
      });

     
  };
 
  return (
    <>
      <div className="coursehero main-container">
        <div className="container">
          <CustomAlert data={alertData} />
          <div className="row">
            <div className="item1 col-lg-6  py-lg-5">
              <h2 className="heading custom-heading-meta">
                <b>{course.title}</b>
              </h2>

              <p className="custom-lorem-p">{course.short_description}</p>

              <div className="pb-3 trate">
                <span
                  className="color"
                  style={{ color: "#FFB33E", fontWeight: "bold" }}
                >
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i> 4.9
                </span>
              </div>

              <div className="instructor">
                {" "}
                <img src="./assets1/image/Ellipse 2.png" alt="" />
                {course.tutor
                  ? course.tutor.firstname + " " + course.tutor.lastname
                  : ""}{" "}
              </div>
            </div>

            <div className="item2 col-lg-6 py-3">
              <div className="videohere p-4">
                <div className="video-wrapper__overlay"></div>

                <iframe
                  width="500"
                  height="250"
                  src={course.intro_video_link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                ></iframe>
              </div>
            </div>

            <div className="row mt-lg-5">
              <div className="col-lg-3 col-6 mb-2 mb-md-2 text-center">
                <button
                  type="button"
                  className="btn custom-btn-add"
                  data-bs-toggle="modal"
                  data-bs-target="#addTopicModal"
                  data-whatever="@mdo"
                >
                  Add topic <i className="bi bi-plus-circle text-black  "></i>
                </button>
              </div>

              <div className="col-lg-3 col-6 mb-2 mb-md-2 text-center">
                <button
                  type="button"
                  className="btn custom-btn-add"
                  data-bs-toggle="modal"
                  data-bs-target="#addObjectiveModal"
                  data-whatever="@fat"
                >
                  {" "}
                  Add objective{" "}
                  <i className="bi bi-ui-checks-grid text-black pl-2 "></i>
                </button>
              </div>
              <div className="col-lg-3 col-6 mb-2 mb-md-2 text-center">
                <button
                  type="button"
                  className="btn custom-btn-add"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  data-whatever="@getbootstrap"
                >
                  Add intro video{" "}
                  <i className="bi bi-camera-video text-black "></i>
                </button>
              </div>
              <div className="col-lg-3 col-6 mb-2 mb-md-2 ">
                <select
                  className="form-select custom-btn-add text-center m-auto"
                  aria-label="Default select example"
                  style={{ border: "1px solid transparent" }}
                  onChange={updateCourseStatus}
                >
                  <option defaultValue={"hidden"} disabled>
                    Display
                  </option>
                  <option value="0">Showing</option>
                  <option value="1">Hidden</option>
                </select>
              </div>
            </div>

            <div
              className="modal fade"
              id="addTopicModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addIntroVideoModal"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add topic</h5>
                    <button
                      type="button"
                      className="modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="bi bi-x-circle"
                        style={{ fontSize: "20px" }}
                        target="addTopicModal"
                      ></i>
                    </button>
                    <button
                      type="button"
                      className="btn-close d-none"
                      data-bs-dismiss="modal"
                      data-bs-target="#addTopicModal"
                      id="close-addTopicModal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Topic:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="recipient-name"
                          onChange={updateNewTopic}
                          ref={topicInputRef}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                       className="academy-btn btn-primary border-primary p-md-btn"
                      onClick={submitTopic}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="addObjectiveModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addObjectiveModa"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addObjectiveModal">
                      Add Objective
                    </h5>
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="bi bi-x-circle"
                        style={{ fontSize: "20px" }}
                        target="addObjectiveModal"
                      ></i>
                    </button>
                    <button
                      type="button"
                      className="btn-close d-none"
                      data-bs-dismiss="modal"
                      data-bs-target="#addObjectiveModal"
                      id="close-addObjectiveModal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label
                          htmlFor="message-text"
                          className="col-form-label"
                        >
                          Objective:
                        </label>
                        <textarea
                          className="form-control"
                          id="message-text"
                          onChange={updateNewObjective}
                          ref={objectiveInputRef}
                        ></textarea>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                       className="academy-btn btn-primary p-md-btn border-primary"
                      onClick={submitObjective}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="exampleModal3"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="addIntroVideoModal"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addIntroVideoModal">
                      Add Intro Video
                    </h5>

                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="bi bi-x-circle"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Enter url link:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="video"
                          onChange={(e) => setVideo(e.target.value)}
                          id="recipient-name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      onSubmit={handleSubmit}
                       className="academy-btn btn-primary p-md-btn border-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

     
      <div className="nav nav-tabs nav-fill mt-5" id="nav-tab" role="tablist">
          <button className="nav-link text-capitalize active text-dark" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Topics
          </button>
          <button className="nav-link text-capitalize text-dark" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="true">Objectives
          </button>
          <button className="nav-link text-capitalize text-dark" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="true">Enrollments
          </button>
          {/* <button className="nav-link text-capitalize text-dark" id="nav-disabled-tab" data-bs-toggle="tab" data-bs-target="#nav-disabled" type="button" role="tab" aria-controls="nav-disable" aria-selected="true">disabled
          </button> */}
      </div>
      <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
            <div className="container p-4">
              <div className="row p-1 ">
                <div className="card bg-white custom-upload-card ">
                  <div className="table-responsive-sm">
                    <table className="table text-center">
                      <thead>
                        <tr className="text-start custom-tr">
                          <th scope="col">Title</th>
                          <th scope="col">Description</th>
                          <th scope="col">Resources</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {course.topics.map((topic, index) => (
                            <tr className="text-center py-1" key={index} style={{ whiteSpace:"pre-wrap" }}>
                              <th className="text-start">{topic.topic}</th>
                              <td className="text-start">{topic.detail}</td>
                              <td className="text-start">{topic.external_resources}</td>
                              {/* <td><img src={window.$base_url+'/storage/'+course.cover_photo} alt="" width={50} /></td> */}
                              <td>
                                <div className="row mt-3    ">
                                  <div className="col-6">
                                    <span 
                                      className="custom-icon-div p-2"
                                      data-bs-toggle="modal" 
                                      data-bs-target={`#exampleModal-${topic.slug}`}
                                      style={{ cursor:"pointer" }}
                                    >
                                      <i className="bi bi-trash3 text-white"></i>
                                    </span>
                                  </div>
                                  <div className="col-6">
                                      
                                    <span 
                                      className="custom-icon-div1 p-2"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#addCourseDescriptionModal-${topic.slug}`}
                                      data-whatever="@fat"
                                      style={{ cursor:"pointer" }}
                                    >
                                      <i className="bi bi-pencil-square text-white "></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>

                        ))}
                        
                      </tbody>
                    </table>
                  </div>

                 
                
                
                </div>
              </div>
            </div>
          </div>
        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
          <div className="container p-4">
          <div className="row p-1 ">
            <div className="card bg-white custom-upload-card ">
              <h4 className="p-4 custom-list-upload">
                List of uploaded objective(s)
              </h4>
              <div className="table-responsive-sm">
                <table className="table text-start">
                  <thead>
                    <tr className="custom-tr">
                      <th scope="col">Title</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Discounted price</th>
                      <th scope="col">Original price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {course.objectives.map((objective, index) => (
                      <tr className="" key={index} style={{ whiteSpace:"pre-wrap" }}>
                        <th>{objective.objective}</th>
                        <td>2weeks</td>
                        <td>$50</td>
                        <td>$100</td>
                        <td>
                          <div className="row mt-3    ">
                            <div className="col-6">
                              <span 
                                className="custom-icon-div p-2" style={{cursor:"pointer"}}
                                data-bs-toggle="modal"
                                data-bs-target={`#delete-objective-modal-${objective.id}`}
                              >
                                <i className="bi bi-trash3 text-white"></i>
                              </span>
                            </div>
                            
                            <div className="col-6">
                                  
                                <span 
                                  className="custom-icon-div1 p-2"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#edit-objective-modal-${objective.id}`}
                                  data-whatever="@fat"
                                >
                                  <i className="bi bi-pencil-square text-white "></i>
                                </span>
                              </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabIndex="0">...</div>
        {/* <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">...</div> */}
      </div>

      {course.topics.map((topic, index) => (

        <div  key={index}>
            <div
                className="modal fade"
                id={`addCourseDescriptionModal-${topic.slug}`}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addCourseDescriptionModa"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                  <form topic-slug={topic.slug} onSubmit={updateTopic} topic-index={index}>
                    <div className="modal-header">
                      <h5 className="modal-title text-capitalize" id={`addCourseDescriptionModal-${topic.slug}`}>
                        Add Topic Detail
                      </h5>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i
                          className="bi bi-x-circle"
                          style={{ fontSize: "20px" }}
                          target={`addCourseDescriptionModal-${topic.slug}`}
                        ></i>
                      </button>
                      <button
                        type="button"
                        className="btn-close d-none"
                        data-bs-dismiss="modal"
                        data-bs-target={`#addCourseDescriptionModal-${topic.slug}`}
                        id={`close-addCourseDescriptionModal-${topic.slug}`}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      
                        <div className="form-group">
                          
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            Topic
                          </label>
                          <textarea
                            className="form-control"
                            id="message-text"
                            onChange={(e) => {
                              const updatedCourse = {...course};
                              updatedCourse.topics[index].topic= e.target.value;
                              setCourse(updatedCourse)

                            }}
                            defaultValue={topic.topic}

                            
                          ></textarea>
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            Detail
                          </label>
                          <textarea
                            className="form-control"
                            id="message-text"
                            onChange={(e) => {
                              const updatedCourse = {...course};
                              updatedCourse.topics[index].detail = e.target.value;
                              setCourse(updatedCourse)
                              console.log(course)

                            }}
                            defaultValue={topic.detail}

                            
                          ></textarea>
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            Resources
                          </label>
                          <textarea
                            className="form-control"
                            id="message-text"
                            onChange={(e) => {
                              const updatedCourse = {...course};
                              updatedCourse.topics[index].external_resources = e.target.value;
                              setCourse(updatedCourse)

                            }}
                            defaultValue={topic.external_resources}
                          ></textarea>
                        </div>
                      
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                         className="academy-btn btn-primary border-primary p-md-btn"
                        data-bs-dismiss="modal"
                        data-bs-target={`#addCourseDescriptionModal-${topic.slug}`}
                        id={`close-addCourseDescriptionModal-${topic.slug}`}
                        aria-label="Close"
                      >
                        Submit
                      </button>
                    </div>
                    </form>
                  </div>
                </div>
            </div>
                   
          
           <div className="modal fade" id={`exampleModal-${topic.slug}`} tabIndex="-1" aria-labelledby={`exampleModal-${topic.slug}`}aria-hidden="true" key={index}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5 text-capitalize" id={`exampleModal-${topic.slug}`}>Delete topic</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-mormal">
                  Are you sure you want to delete {topic.slug}?
                  
                </div>
                <div className="modal-footer">
                  <button type="button"  className="academy-btn btn-gray p-sm-btn" data-bs-dismiss="modal"><i className="bi bi-arrow-left"></i> Go Back</button>
                  <button type="button"  className="academy-btn btn-primary p-sm-btn border-primary" delete-index={topic.slug} onClick={deleteTopic} data-bs-dismiss="modal">Proceed<i className="bi bi-arrow-right"></i></button>
                </div>
              </div>
            </div>
           </div>
        </div>
        
       ))}

       
      {course?.objectives?.map((objective, index)=>(
        <div key={index}>
          <div className="modal fade" id={`edit-objective-modal-${objective.id}`} tabIndex="-1" aria-labelledby={`edit-objective-modal-${objective.id}`}aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form objective-id={objective.id} onSubmit={updateObjective} objective-index={index}>
                  <div className="modal-header">
                    <h1 className="modal-title fs-5 text-capitalize">Edit Course Objectives</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <button
                          type="button"
                          className="btn-close d-none"
                          data-bs-dismiss="modal"
                          data-bs-target={`#edit-objective-modal-${objective.id}`}
                          id={`close-edit-objective-modal-${objective.id}`}
                          aria-label="Close"
                        ></button>
                  </div>
                  <div className="modal-body text-mormal">
                        <div className="form-group">
                          <label
                            htmlFor="message-text"
                            className="col-form-label"
                          >
                            Objective:
                          </label>
                          <textarea
                            className="form-control"
                            rows="5"
                            defaultValue={objective.objective}
                          
                            onChange={(e) => {
                              const updatedCourse = {...course};
                              updatedCourse.objectives[index].objective = e.target.value;
                              setCourse(updatedCourse)
                              console.log(updatedCourse.objectives[index].objective)
                            }}
                          ></textarea>
                        </div>
                  </div>
                
                  <div className="modal-footer">
                    <button type="button"  className="academy-btn btn-gray p-md-btn" data-bs-dismiss="modal">Close</button>
                    <button type="submit"  className="academy-btn btn-primary p-md-btn border-primary"> Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal fade" id={`delete-objective-modal-${objective.id}`} tabIndex="-1" aria-labelledby={`delete-objective-modal-${objective.id}`}aria-hidden="true" key={index}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5 text-capitalize">Delete objective</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <button
                  type="button"
                  className="btn-close d-none"
                  data-bs-dismiss="modal"
                  data-bs-target={`#delete-objective-modal-${objective.id}`}
                  id={`close-delete-objective-modal-${objective.id}`}
                  aria-label="Close"
                ></button>
                <div className="modal-body text-mormal">
                  Are you sure you want to delete objective?
                  
                </div>
                <div className="modal-footer">
                  <button type="button"  className="academy-btn btn-secondary border-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-arrow-left"></i> Go Back</button>
                  <button type="button"  className="academy-btn btn-sm btn-primary border-primary" delete-index={objective.id} onClick={deleteObjective} data-bs-dismiss="modal">Proceed <i className="bi bi-arrow-right"></i></button>
                </div>
              </div>
            </div>
           </div>
        </div>
          
      ))}
    </>
  );
};

export default Management;
