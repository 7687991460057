import React from 'react';
import CourseUpload from "../includes/CourseUpload";
import Footer from "../includes/Footer";
import TutorNavbar from "../includes/TutorNavbar";


const Upload = () => {

    return(
        <>
        <TutorNavbar />
        <CourseUpload />
        <Footer />
        </>
    )
}

export default Upload;