import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import StarIcon from "./StarIcon";
import { useCookies } from "react-cookie";

const Course = (props)=> {
    const [sum, setSum] = useState(0);
    const [avgRate, setAvgRate] = useState(2.00);
    const [stars, setStars] = useState([]);
    // const [cookies, setCookies] = useCookies(['user']);
    // const [userType, setUserType] = useState(props.userType)
    console.log(props.userType)

    // const [stars, setStars] = useState([
    //     {
    //         icon:"fa-star",
    //         type:"solid"
    //     },
    //     {
    //         icon:"fa-star",
    //         type:"solid"
    //     },

    //     {
    //         icon:"fa-star",
    //         type:"regular"
    //     },
    //     {
    //         icon:"fa-star",
    //         type:"regular"
    //     },
    //     {
    //         icon:"fa-star",
    //         type:"regular"
    //     }

    // ]);
    // let dollarUSLocale = Intl.NumberFormat('en-US');
   
    

    function createStars(rate){
        let nStars = [];
        for(let i=0; i < 5;  i++){
            if(nStars.length < 5 ){
                if(rate <= i){
                    nStars.push({
                        icon:"fa-star",
                        type:"regular"
                    })
                }else if(rate > i && rate < i+1){
                    // nStars.push(<StarIcon key={i} starClass="fa-star-half-stroke" starType="solid"/>)
                    nStars.push({
                        icon:"fa-star-half-stroke",
                        type:"solid"
                    })
                }else{
                    // nStars.push(<StarIcon key={i} starClass="fa-star" starType="solid"/>)
                    nStars.push({
                        icon:"fa-star",
                        type:"solid"
                    })
                }
            }
            
        }
        // setStars(nStars);
        return nStars;
    }

   

    useEffect(()=>{
       
       let eachRate = 0;
        props.ratings.forEach(element => {
            eachRate = parseFloat(element.rating) + eachRate;
            setSum(eachRate);
            // createStars(avgRate)
        });

       

        if(sum > 0){
            
            let tempAvg = sum / props.ratings.length;
            
            setAvgRate(tempAvg.toFixed(2));

            const aav = createStars(parseFloat(tempAvg.toFixed(2)));
            setStars(aav);
            return;
            // console.log(avgRate);
            // console.log(typeof parseFloat(avgRate))
            // createStars(parseFloat(avgRate))
        } else{
            const aav = createStars(parseFloat(avgRate));
            setStars(aav);
            // return;
            // console.log(avgRate);
        }
        
        // createStars(parseFloat(avgRate))
    },[props.ratings])
    // console.log(avgRate)


    return(
        
        <div className="contain card border-0 shadow single-course" id="zoomin">
           
            <div className="fig"><img src={props.img} alt="" /></div>
            <div className="content">
                <h6>{props.title}</h6>
                <span>{props.duration}</span><br />
                <span className="icons">

                    <span className="num">{avgRate}</span> 
                    {/* (typeof aav != 'undefined'?
                        {aav.map((item, index)=>(
                            <StarIcon key={index} starType={item.type} starClass={item.icon} /> 
                        ))}
                    :  */}
                        {stars.map((star, index)=>(
                            <i key={index} className={`${star.icon} fa-${star.type}`}></i>
                            // <StarIcon key={index} starType={item.type} starClass={item.icon} /> 
                        ))}
                    {/* ) */}
                    

                </span>

                <p className="text-dark"> 
                    ${props.span.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                    <sub><del className="text-muted">${props.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del></sub>
                </p>
                <div className="text-center">
                {props.userType != 'undefined' && props.userType != 'tutor' ?

                    <Link to={`/courses/${props.slug}`} className="academy-btn btn-secondary p-sm-btn">Learn more<i className="px-1 fa-solid fa-arrow-right-long"></i></Link>
                :
                    <Link to={`/courses/${props.slug}/manage`} className="academy-btn btn-secondary p-sm-btn">Manage Course<i className="px-1 fa-solid fa-arrow-right-long"></i></Link>
                }
                </div>

            </div>
        </div>     
    );
}

export default Course;