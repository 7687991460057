import React from 'react';
import Footer from '../includes/Footer';
import Management from '../includes/Management';
import TutorNavbar from '../includes/TutorNavbar';
import TutorSideNav from '../includes/TutorSideNav';

const CourseManagement = ()=> {

    return(
        <>
        <TutorNavbar />
        <div className="classroom main-container custom-upload-container">
        
                <div className="row px-lg-5 px-2 justify-content-center course py-md-5" id="course">
                    <div className="">

                       
                        <TutorSideNav />
                    </div>
                   
                
                    <div className="col-md-10">
                        <Management />
                    </div>
                </div>
            </div>
       
        <Footer />
        </>
    )
}

export default CourseManagement;