export default function CourseService(){

    const  fetchCourse =  async (auth_token=null, userType=null, items=null, category = null)=>{
        
        if(userType === 'tutor'){
            let requestInfo = fetch(window.$base_url+'/api/tutor/courses', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${auth_token}`
                },
                body: JSON.stringify()
            })
           
            return requestInfo;
        }else{
           
           

            const url = category === null ? (typeof items == "number" ? `${window.$base_url}/api/courses?items=${items}` : `${window.$base_url}/api/courses`) : (typeof items == "number" ? `${window.$base_url}/api/courses?items=${items}&category=${category}` : `${window.$base_url}/api/courses?category=${category}`);
            

            let requestInfo = fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${process.env.REACT_APP_APP_TOKEN}`
                },
                body: JSON.stringify()
            })
            
            return requestInfo;
        }  
        
    }

    const fetchCourseCategories = async ()=>{
        return fetch(`${window.$base_url}/api/courses/categories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${process.env.REACT_APP_APP_TOKEN}`
            },
            body: JSON.stringify()
        });
    }

    
    return {
        fetchCourse,
        fetchCourseCategories
    }

}
