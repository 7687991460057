import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie";
import CourseService from "../../services/CourseService";
import {Link} from 'react-router-dom';

const CoursesTable =({userType, items, category})=> {
    const [ courses, setCourses ] = useState([]);
    const [slideItems, setSlideItems] = useState([]);
    
    const [cookies, setCookies] = useCookies(['user']);

    const {fetchCourse} = CourseService();
    
    const updateCourseTable = (resp)=>{
        if(resp !=null ){
            return resp.then((res) => res.json())
            .then((data) => {
                let actualData = (data.data.data);
                setCourses(actualData);
            })
            .catch(err=>{
                console.log(err)
            })
        }
    }
    
    useEffect(() => {
        updateCourseTable(fetchCourse(cookies?.user?.token, userType, items, category))
    }, [])

    return(
        
            <div className="table-responsive-sm p-0 ">
                <table className="table text-center">
                    <thead>
                    <tr className="text-center custom-tr">
                        <th scope="col">Cover photo</th>
                        <th scope="col">Course Title</th>
                        <th scope="col">Original Price</th>
                        <th scope="col">Discounted price</th>
                        <th scope="col">Duration</th>
                    </tr>
                    </thead>
                    <tbody>
                        
                    {courses.map((course, index) => (
                        <tr className="text-center" key={index}>
                            <td>
                                <img src={window.$base_url+'/storage/'+course.cover_photo} className="rounded-circle" style={{ objecFit:"cover", height:"60px", width:"60px" }} />
                            </td>
                            <th scope="row">{course.title}</th>
                            <td>$ {course.original_price} </td>
                            <td>{course.discounted_price}</td>
                            <td>{course.duration}</td>
                            <td>
                                <div className="row mt-3    ">
                                    <div className="col-4">
                                        <Link to={`/courses/${course.slug}/manage`}>
                                        <span className="custom-icon-div p-2">
                                            <i className="bi bi-eye text-white"></i>
                                        </span>
                                        </Link>
                                    </div>
                                    <div className="col-4">
                                        <Link to={`/courses/${course.slug}/edit`}>
                                        <span className="custom-icon-div1 p-2">
                                            <i className="bi bi-pen text-white "></i>
                                        </span>
                                        </Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            
    )
}

export default CoursesTable;