import React from "react";
import {Link} from "react-router-dom"

const Testimonial=(props)=>{

    return(
        <div className="testi-item">
            <div className="testi-avatar"><img src={props.avatar}  alt=""/></div>
            <div className="testimonials-text-before"><i className="fa fa-quote-right"></i></div>
            <div className="testimonials-text">
                <div className="listing-rating">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                </div>
                <p>{props.testimony}</p>
                <Link to="#" className="text-link"></Link>
                <div className="testimonials-avatar">
                    <h3>{props.name}</h3>
                    <h4>{props.post}</h4>
                </div>
            </div>
            <div className="testimonials-text-after"><i className="fa fa-quote-left"></i></div>
        </div>
    
    );
}

const Testimonials =()=> {
    const testimonialList =[
        {   
            id: 1,
            avatar: "assets/images/testimonials/ehi.jpg",
            testimony: "Stormcell makes learning comfortable. The environment is conducive. They teach you till you know your stack very well and they don't fail to involve you in projects that sharpen your skill.",
            name: 'Ehi' 
        },
        {
            id:2,
            avatar: "assets/images/testimonials/joce.jpg",
            testimony: 'Learning at Stormcell was productive since a real life approach was used. The best way to learn is by involving in projects after graduation and that happened a lot. It feels good to have Stormcell hold my hand throughout my learning journey.' ,
            name: 'Debe' 
        },
        {   
            id:3,
            avatar: "assets/images/testimonials/tasie.jpg",
            testimony: 'My learning experience here was smooth. My tutor was good at his work. Stormcell has a great ambience and good learning and working conditions.' ,
            name: "God'sfavour"
        },
        {   
            id:4,
            avatar: "assets/images/testimonials/tom.jpg",
            testimony: 'Learning with Stormcell is great. They ensure that you understand and give you time to practise, they also give you room for questions and contributions. Added advantage is free internet and other learning tools.' ,
            name: "God'swill" 
        },
    ]

    return(
        <section className="testimonial-section">
            <div className="container">
                <div className="section-title">
                    <h2>Testimonials</h2>
                    <span className="section-separator"></span>
                    <p>Here, we do things harmoniously and the impact is incredible! .</p>
                </div>
            </div>
            <div className="testimonials-carousel-wrap">
                <div className="listing-carousel-button listing-carousel-button-next"><i className="fa fa-caret-right" style={{color:'white'}}></i></div>
                <div className="listing-carousel-button listing-carousel-button-prev"><i className="fa fa-caret-left" style={{color:'white'}}></i></div>
                <div className="testimonials-carousel">
                    <div className="swiper-container">
                <div className='swiper-wrapper'>
                {testimonialList.map((testimonial) => {
                return (
                    <div className="swiper-slide" key = {testimonial.id}>
                <Testimonial 
                avatar = {testimonial.avatar}
                testimony = {testimonial.testimony}
                name = {testimonial.name}
                />
                </div>
                )
            })}
            
                </div>
                    </div>
                </div>

                <div className="tc-pagination"></div>
            </div>
        </section>
    );
}

export default Testimonials;