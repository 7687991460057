export default function EnrollmentService(){

      const  fetchTutorEnrollments =  async (tutor_token)=>{
        let requestInfo = fetch(window.$base_url+'/api/tutor/enrollments', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${tutor_token}`
            },
            body: JSON.stringify()
        })
       
        return requestInfo;
    }

    return {
        fetchTutorEnrollments

    }

}
