import React from 'react'
import Navbar from '../includes/Navbar'

const PaymentSuccess = () => {
  return (
    <>
        <Navbar />
        <div>
            <div className="course  main-container mt-5">
                <div className="serve">
                    <div className="row justify-content-center py-3 px-2 mx-2">
                        <div className=" col-sm-6 col-md-5 col-lg-4 col-xl-4">
                            <div className="card bg-light cust-card text-center">
                                <div className="row justify-content-center py-4">
                                    <div className="col-md-3 d-lg-block d-none col-lg-2">
                                        <div className="custom-hr-section"></div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="text-center p-0"><strong style={{fontSize: "40px", color: "rgb(7, 122, 3)"}}><i className="fa-solid fa-user-check"></i></strong></div>
                                    </div>
                                    <div className="col-md-3 d-lg-block d-none col-lg-2">
                                        <div className="custom-hr-section"></div>
                                    </div>
                                    
                                </div>
                                <h4 style={{color: "rgb(236, 118, 6)"}}>Transaction Completed<br /> Successfully</h4>

                                <h5 className="my-4" style={{fontFamily: "'Courier New', Courier, monospace"}}>Your Submission is recieved and<br /> we will contact you soon</h5>

                                <h6 className="" style={{color: "rgb(7, 122, 3)"}}>Thank You !</h6>
                                <hr style={{marginLeft: "170px", width: "80px" }}/>
                                <small style={{fontWeight: "bold"}}><a href="#" style={{color: "black"}}>Back</a></small>                    
                            </div>
                        </div>                
                    </div>                   
                </div>      
            </div>
        </div>
    </>
  )
}

export default PaymentSuccess