import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import CustomAlert from "./CustomAlert";
import { useNavigate } from "react-router-dom";
import TutorSideNav from "./TutorSideNav";
import CourseService from "../../services/CourseService";

import { triggerLoad, stopLoading } from "../../utils/generic";

const CourseUpload = () => {
    const navigate = useNavigate();
    const [ cookies, setCookies ] = useCookies(['user']);
    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        color: ""
    });
    const [courseData, setCourseData] = useState({});
    const [categories, setCategories] = useState([]);
    const {fetchCourseCategories} = CourseService();

    const addCourse= async(ev)=> {
        const btn = ev.target;
       
        const btnLoading = triggerLoad(btn);
       
        const data = new FormData();

        for(var field in courseData){
            if(field != 'cover_photo'){
                data.append(field, courseData[field]);
            }
        }
        data.append('cover_photo', courseData['cover_photo']);
        
        return fetch(window.$base_url+'/api/courses/add', {
            method: 'POST',
            headers: {
                "Accept": "*/*",
                "Authorization": `Bearer ${cookies.user.token}`
            },
            body: data
           
        })
        .then((res) => res.json())
        .then((feedback) => {
            if('status' in feedback){
               
                if(feedback.status == 'fail'){
                   
                    setAlertData({
                        title: "oops!",
                        message: feedback.error,
                        color: "danger"
                    })

                }
                if(feedback.status == 'success'){
                    navigate('/courses/'+feedback.course.slug+'/edit');
                }
            }else{
                alert('Something went wrong');
            }
            stopLoading(btn, btnLoading)
        })
        .catch(err=>{
            alert(err)
            stopLoading(btn, btnLoading)
        })
        
    }

    function handleChange(event) {
        let value = event.target.value;
        let name = event.target.name;
        setCourseData((prevCourseData) => {
            return{
            ...prevCourseData,
            [name] : value
            }
            
        })
    }

    function handleFileUpload(event){
        let value = event.target.files[0];
        courseData['cover_photo'] = value;
    }

    const getCourseService = (resp)=>{
        let results = [];
        if(resp !=null ){
            return resp.then((res) => res.json())
            .then((data) => {
                let actualData = (data.data);
                setCategories(actualData)
            })
            .catch(err=>{
                console.log(err)
            })
        }  
       
    }

    useEffect(()=>{
        getCourseService(fetchCourseCategories());
    }, []);
    return(
        <>
        <div className="classroom main-container custom-upload-container">
        
        <div className="row px-lg-5 px-2 justify-content-center ">
            <div className="">
                <TutorSideNav />
            </div>

            <div className="col-xl-9">
                <CustomAlert data = {alertData} />
                <div className="card bg-white custom-upload-card px-lg-5">
                    <h4 className="mt-4 ms-3 custom-upload-heading">
                        Course upload
                    </h4>
                    <form  method="post">
                         <div className="row justify-content-center p-3">
                             <div className="col-md-6">
                                 <div className="input-group custom-group-input my-4">
                                    <div><span className="input-group-text custom-icon-circle"><i className="bi bi-book"></i></span>
                                    </div>
                                     <input type="text" className="form-control py-1 custom-input-circle" 
                                     placeholder="Course title"
                                     name="title"
                                     onChange={handleChange}
                                     value={courseData.title} 
                                     aria-label="Username" required />
                                 </div>
                                 <div className=" my-md-5 mb-3  custom-input-textarea">
                                    <textarea  id=""  rows="4" className="custom-textarea form-control p-1" 
                                    placeholder="Write a short description about the course"
                                    name="short_description"
                                    onChange={handleChange} 
                                    value={courseData.short_description}
                                    ></textarea>
                                </div>
                                <div className="my-md-5 mb-3 custom-input-textarea">
                                    <textarea name="long_description" id=""  rows="4" className="custom-textarea form-control" 
                                    placeholder="Write a long description about the course"
                                    onChange={handleChange} 
                                    value={courseData.long_description}
                                    ></textarea>
                                </div>
                             </div>
                             <div className="col-md-6">
                                <div className="my-4 mb-3 input-group custom-group-input">
                                    <div>
                                        <span className="input-group-text  custom-icon-circle">
                                            <i className="bi bi-cloud-arrow-up"></i></span>
                                    </div>
                                    <input type="file" className="form-control  m-auto custom-input-circle-file" 
                                    placeholder="" aria-label="" required 
                                    name="cover_photo"
                                    onChange={handleFileUpload} 
                                    />
                                </div>
                                <div className="mt-md-5  mb-3 input-group custom-group-input">
                                    <div>
                                        <span className="input-group-text  custom-icon-circle"><i className="bi bi-currency-dollar"></i></span>
                                    </div>
                                     <input type="text" className="form-control py-1 custom-input-circle" 
                                     placeholder="Original price"
                                     name="original_price"
                                     onChange={handleChange}
                                     value={courseData.original_price} 
                                     aria-label="Username" required />
                                </div>
                                <div className="mt-md-5  mb-3 input-group custom-group-input">
                                    <div>
                                        <span className="input-group-text custom-icon-circle">
                                        <i className="bi bi-currency-dollar"></i></span>
                                    </div>
                                    <input type="text" className="form-control py-1 custom-input-circle" 
                                    placeholder="Discounted price"
                                    name="discounted_price"
                                    onChange={handleChange}
                                    value={courseData.discounted_price}
                                    aria-label="Username" required /> 
                                </div>
                                <div className="mt-md-5 input-group custom-group-input custom-group-input1 ">
                                    <div>
                                        <span className="input-group-text  custom-icon-circle"><i className="bi bi-hourglass"></i></span>
                                    </div>
                                    <input type="text" className="form-control py-1 custom-input-circle" 
                                    placeholder="Duration (weeks)"
                                    name="duration"
                                    onChange={handleChange} 
                                    value={courseData.duration}
                                    aria-label="Username" required />
                                </div>
                                <div className="mt-md-5 input-group custom-group-input custom-group-input1 ">
                                    <div>
                                        <span className="input-group-text  custom-icon-circle"><i className="bi bi-list"></i></span>
                                    </div>
                                    <select className="form-control py-1 custom-input-circle" name="category" onChange={handleChange} required>
                                        <option value="">Select Category</option>
                                        {categories.map((category, index)=>(
                                            <option value={category.id} key={category.id}>{category.name}</option>
                                        ))}
                                    </select>
                                    
                                </div>
                             </div>
                         </div>

                        <div className="row justify-content-center mt-3 mb-4">
                            <button className="academy-btn btn-primary p-md-btn custom-upload-button" type="button" onClick={addCourse}>Submit </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}

export default CourseUpload;