import { useCookies } from "react-cookie";
import Footer from "../includes/Footer";
import TutorNavbar from "../includes/TutorNavbar";
import PurchasedCourses from "../includes/PurchasedCourses";
import SideNav from "../includes/SideNav";

export default function EnrolledCourses(){
    return(
        <>
           
            
            <div className="classroom main-container custom-upload-container">
                <div className="row px-lg-5 px-2 justify-content-center ">
                <TutorNavbar />
                    <div className="">
                        <SideNav />
                    </div>
                    <div className="col-xl-9">

                        <div className="page-body col-sm-12" style={{backgroundColor: "white"}}>
                            <div className="courses  my-1">
                                <div className="container p-4">
                                    <div className="row p-1 ">
                                    <div className="card bg-white custom-upload-card ">
                                        <h4 className="p-4 custom-list-upload">
                                        List of Enrolled courses
                                        </h4>
                                        <PurchasedCourses />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}