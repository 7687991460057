import React from 'react';
import Footer from '../includes/Footer';
import Navbar from '../includes/Navbar';
//import { Link } from 'react-router-dom';
import TutorRegisterForms from '../includes/TutorRegisterForms';

const TutorRegister = () => {

    return (
        <>
            <Navbar />
            <div className="course  main-container">
                <div className="serve">
                    <div className="row justify-content-center py-4">
                    <div className="col-md-12 ">
                            <div className="text-center p-0"><strong style={{fontSize: "25px"}}>Sign up as a Tutor</strong></div>
                        </div>
                        {/* <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div> */}
                        
                        {/* <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div>   */}
                    </div>
                    <TutorRegisterForms />  
                </div>
            </div>
            <Footer />
        </>
     );
}
 
export default TutorRegister;