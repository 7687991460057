import { useState, useEffect } from "react"
import { useCookies } from "react-cookie";
import Footer from "../includes/Footer";
import TutorNavbar from "../includes/TutorNavbar";
import SideNav from "../includes/SideNav";

export default function PaymentHistory(){

    const [payments, setPayments] = useState([]);
    const [cookies, setCookies]  = useCookies(['user']);

    
    const fetchPayments = async ()=>{
        await fetch(window.$base_url+'/api/payments/history', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${cookies.user.token}`
            },
            body: JSON.stringify()
        })
        .then((res) => res.json())
        .then((data) => {
            let actualData = (data.data);
            
            setPayments(actualData);
            console.log(actualData)
        })
        .catch(err=>{
            console.log(err)
            // alert(err)
        })
    }
    
    useEffect(() => {
        fetchPayments();
    }, [])
    
    return(
        <>
            
            <div className="classroom main-container custom-upload-container">
                <div className="row px-lg-5 px-2 justify-content-center ">
                <TutorNavbar />
                    <div className="">
                        <SideNav />
                    </div>
                    <div className="col-xl-9">
                        <div className="page-body col-sm-12" style={{backgroundColor: "white"}}>
                            <div className="courses  my-1">
                                <div className="container p-4">
                                    <div className="row p-1 ">
                                        <div className="card bg-white custom-upload-card ">
                                            <h4 className="p-4 custom-list-upload">
                                                Payment History
                                            </h4>
                                            <div className="table-responsive-sm">
                                                <table className="table text-center">
                                                    <thead>
                                                    <tr className="text-center custom-tr">
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Reference</th>
                                                        <th scope="col">Description</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {payments.map((payment, index) => (
                                                            <tr className="text-center" key={index}>
                                                                <th scope="row">{payment.amount}</th>
                                                                <td><span className={`badge bg-${payment.status=='success'?'success':'secondary'}`}>{payment.status}</span> </td>
                                                                <td>{payment.reference}</td>
                                                                <td>
                                                                    <p style={{ whiteSpace:"pre-wrap" }}>{payment.description}</p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}