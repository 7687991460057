import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CourseOutline from "../includes/CourseOutline";
import Footer from "../includes/Footer";
import Navbar from "../includes/Navbar";
import { Accordion } from "react-bootstrap";
import { createStars } from "../../utils/ratingsUtils";
import StarIcon from "../includes/StarIcon";

const CourseDetail = () => {
  const [course, setCourse] = useState({
    topics: [],
    objectives: [],
  });
  const { slug } = useParams();
  
  const [avgRate, setAvgRate] = useState(2.0);
  const [avgStars, setAvgStars] = useState([]);
  const [sum, setSum] = useState(0);

  async function fetchCourse() {
    return fetch(window.$base_url+ "/api/courses/" + slug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        let actualData = data.data;
        setCourse(actualData);
        console.log(actualData);
      })
      .catch((err) => {
        alert("error");
      });
  }



  function showEnrollModal() {
    const enrollModal = document.querySelector(".modal-card");
    enrollModal.style.display = "block";

    return;
  }

  function closeModal() {
    const hideModal = document.querySelector(".modal-card");
    hideModal.style.display = "none";

    return;
  }

  const getRatingStars = (ratingsArray)=>{

    if (typeof ratingsArray != "undefined") {
      let eachRate = 0;
      ratingsArray.forEach((element) => {
        eachRate = parseFloat(element.rating) + eachRate;
        setSum(eachRate);
        // createStars(avgRate)
      });
    }

    if (sum > 0) {
      let tempAvg = sum / ratingsArray.length;

      setAvgRate(tempAvg.toFixed(2));

      const aav = createStars(parseFloat(tempAvg.toFixed(2)));
      setAvgStars(aav);
      return;
      // console.log(avgRate);
      // console.log(typeof parseFloat(avgRate))
      // createStars(parseFloat(avgRate))
    } else {
      const aav = createStars(parseFloat(avgRate));
      setAvgStars(aav);
      // return;
      // console.log(avgRate);
    }
    console.log(avgStars)
  }


  useEffect(() => {
    fetchCourse();
  }, []);

  useEffect(() => {
    getRatingStars(course?.ratings);
  }, [course]);

  return (
    <>
      <Navbar />
      <div className="coursehero main-container">
        <div className="container">
          <div className="row">
            <div className="item1 col-md-6">
              <h6 className="nextitem py-2">
                {" "}
                <b>
                  <Link to="/" className="text-primary">
                    Home
                  </Link>
                </b>{" "}
                <i className="fa-solid fa-angle-right"></i>{" "}
                <b>
                  <Link to="/courses" className="text-primary">
                    Courses
                  </Link>
                </b>{" "}
                <i className="fa-solid fa-angle-right"></i>{" "}
                <b>{course.title}</b>
              </h6>

              <h2 className="heading">
                <b>{course.title}</b>
              </h2>

              <p>{course?.short_description?.split("\\n").map(function(item, index) {
                return (
                  <span key={index}>
                      {item}
                      <br/>
                  </span>
                )
              })}</p>

              <div className="pb-3 rate">
                <span
                  className="color"
                  style={{ color: "#041066", fontWeight: "bold" }}
                >
                  
                  {avgStars.map((star, index)=>(
                    // <StarIcon key={index} starClass={star.icon} starType={star.type} />
                    <i key={index} className={`${star.icon} fa-${star.type}`}></i>
                  ))} 
                  &nbsp;{avgRate} &nbsp;&nbsp;
                </span>
                <span>
                  ({course.ratings && course.ratings.length > 0 ? course.ratings.length : 2} ratings){" "}
                </span>
              </div>

              <div className="instructor">
                {" "}
                <img src={"./assets1/image/html.jpg"} alt="" />
                {course.tutor
                  ? course.tutor.firstname + " " + course.tutor.lastname
                  : ""}{" "}
              </div>

              <button
                className="academy-btn btn-secondary p-md-btn border-secondary border-1"
                onClick={showEnrollModal}
              >
                 Enrol now
                
              </button>

              <div className="h5">
                <strong style={{ fontWeight: "bold" }}>{course?.enrollments?.length + 15} </strong> already enrolled
              </div>
            </div>

            <div className="item2 col-md-6">
              <img src={`${process.env.REACT_APP_BASE_URL}/storage/${course.cover_photo}`} style={{ height:"100%", width:"100%", objectFit:"cover", objectPosition:"center" }}/>
              {/* <div className="videohere pt-5">
                
                <iframe
                  width="500"
                  height="300"
                  src={course.intro_video_link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="courseobj">
        <div className="container">
          <div className="row">
            <div className="item1 col-md-7 py-3">
              <div className="objbord">
                <h6>WHAT YOU WILL LEARN</h6>
                <hr
                  className="short"
                  style={{
                    width: "4.20849rem",
                    opacity: "5",
                    color: "#ff6f0f",
                  }}
                />
                <div className="container">
                  <div className="row oneflex py-4">
                    {course.objectives.map((instance, index)=>(

                      <div className="flexed col-md-6 col-12" key={index}>
                        <span>
                          <i className="fa-solid fa-check"></i>
                        </span>
                        <span className="">
                         {instance.objective}
                        </span>
                      </div>
                    ))}
                   
                    
                  </div>
                  
                </div>
              </div>
                <div className="about py-4 my-3">
                  <div className="container">
                    <div className="abouttextarea py-5">
                      <h3>About this Professional Certificate</h3>
                      <hr
                        className="short"
                        style={{ width: "4.20849rem", opacity: "5", color: "#ff6f0f" }}
                      />
                      {/* <h6 className="" style={{ fontWeight: "bold" }}>
                        389,981 recent views
                      </h6> */}

                      <p className="maintext text-justify">
                        {course?.long_description?.split("\\n").map(function(item, index) {
                          return (
                            <span key={index}>
                                {item}
                                <br/>
                            </span>
                          )
                        })}
                      </p>
                    </div>
                  </div>
                </div>
            </div>

            <div className="item2 col-md-5 py-3">
              <CourseOutline topics={course.topics} />
            </div>
          </div>
        </div>
      </div>
     
      {course?.ratings?.length > 0 &&
        <div className="ratings py-5">
          <div className="container py-2">
            <div className="flexit row">
              {course?.ratings?.map((rating, index)=>(
                <div className="contain col-md-6 mb-5" key={index}>
                  <div className="row">
                    <div className="col-1 profile">
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="col-11">
                      <h5>{rating.user.firstname} {rating.user.lastname}</h5>
                      <div className="star">
                      
                      {createStars(rating.rating).map((rate, index)=>(
                        <StarIcon starClass={rate.icon} starType={rate.type} />
                      ))}
                      
                        {/* <span> 1 week ago</span> */}
                      </div>
                      <p>
                        {rating?.comment}
                      </p>

                      <div className="thumbs-holder">
                        <div className="text-center rounded-circle thumbs-up-circle mr-3 thumbs">
                          <i
                            className="fa-solid fa-thumbs-up "
                            style={{ marginTop: "14px", fontSize: "22px" }}
                          ></i>
                        </div>
                        <div className="text-center rounded-circle thumbs-down-circle thumbs">
                          <i
                            className="fa-solid fa-thumbs-down"
                            style={{ marginTop: "14px", fontSize: "22px" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              
              
            </div>
          </div>
        </div>
      }
      

      <div className="row">
        <div className="col-md-6 offset-md-3 modal-parent d-flex justify-content-center learning-option">
          <div className="custom-modal-container ">
            <div className="card bg-light px-4 py-2 modal-card ">
              <div className="text-end">
                <span className="modal-close " onClick={closeModal}>
                  <i className="fa fa-xmark custom-close-icon text-danger bg-light"></i>
                </span>{" "}
                <hr />
              </div>
              <h4 className="text-center py-3 ">Activate your Learning Mode</h4>
              <Accordion defaultActiveKey="0">
                 <Accordion.Item eventKey="0">
                  <Accordion.Header className="learning-option-2-header">
                    <i className="fa fa-wifi"></i> &nbsp;&nbsp;&nbsp; Virtual
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="w-75">
                      Join our highly interactive live classes.
                    </p>
                    <Link to={`/courses/${slug}/enrol?method=virtual`} className="academy-btn btn-secondary p-sm-btn">
                      Enroll
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="learning-option-1-header">
                    <i className="fa fa-university"></i> &nbsp;&nbsp;&nbsp;
                    Onsite
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="w-75">
                     
                      Join our one-on-one intensive classes.
                    </p>
                    <Link to={`/courses/${slug}/enrol?method=physical`} className="academy-btn btn-secondary p-sm-btn">
                      Enroll
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
               
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="learning-option-3-header">
                    <i className="fa fa-laptop"></i> &nbsp;&nbsp;&nbsp; Self
                    Paced
                  </Accordion.Header>
                  <Accordion.Body>
                    
                    <p className="w-75">
                      Learn from pre-recorded classes.
                    </p>

                    <button disabled className="academy-btn btn-secondary p-sm-btn">
                      Coming Soon
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetail;
