import React from 'react';
import Footer from '../includes/Footer';
import Navbar from '../includes/Navbar';
import { Link } from 'react-router-dom';
import RegisterForms from '../includes/RegisterForms';

const Register = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const redirect_to = urlParams.get("redirect_to");

    return (
        <>
            <Navbar />
            <div className="course  main-container">
                <div className="serve">
                    <div className="container text-center py-1">
                        <h2 style={{fontSize:"40px"}} >Sign Up</h2>
                        <p>Already have an account? {redirect_to == null ?  <Link to="/login">Sign in</Link> : <Link to={`/login?redirect_to=${redirect_to}`}>Sign in</Link>
                        
                    }</p>
                    </div>
                    {/* <div className="row justify-content-center py-3 px-2 mx-2">
                        <div className=" col-sm-6 col-md-5 col-lg-4 col-xl-4 mb-3">
                            <div className="card bg-light cust-card">
                                <div className="row justify-content-center">
                                    <Link to="#"><h6 className="pt-3 pb-1 pl-2 text-center mb-2 md-mb-0" ><img src="./assets/images/google.png" className="" style={{width: "30px", height: "30px"}} alt="" /> SIGN UP WITH GOOGLE</h6></Link>
                                </div>
                            </div>
                        </div>
                        
                    </div> */}
                    {/* <div className="row justify-content-center py-4">
                        <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div>
                        <div className="col-md-1">
                            <div className="text-center p-0"><strong style={{fontSize: "25px"}}>or</strong></div>
                        </div>
                        <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div>  
                    </div> */}
                    <RegisterForms  redirect={redirect_to} />  
                </div>
            </div>
            <Footer />
        </>
     );
}
 
export default Register;