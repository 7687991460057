import React from "react";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import LoginForm from "../includes/LoginForm";
import Navbar from "../includes/Navbar";

const Login = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const redirect_to = urlParams.get("redirect_to");

    return ( 
        <>
            <Navbar />
            <div className="course  main-container">
                <div className="serve">
                    <div className="container text-center py-3">
                        <h2 style={{fontSize: "40px"}} >Sign In</h2>
                        <p>Don't have an account ? &nbsp;
                            {redirect_to == null ?  <Link to="/register">Sign Up</Link> : <Link to={`/register?redirect_to=${redirect_to}`}>Sign Up</Link>
                        
                        } </p>
                    </div>
                    {/* <div className="row justify-content-center py-3 px-2 mx-2">
                        <div className=" col-sm-6 col-md-5 col-lg-4 col-xl-4">
                            <div className="card bg-light cust-card">
                                <div className="row justify-content-center">
                                    <Link to="#"><h6 className="pt-3 pb-1 pl-2 text-center"><img src="./assets/images/google.png" className="" style={{width: "30px", height: "30px"}} alt="" /> SIGN IN WITH GOOGLE</h6></Link>
                                </div>
                            </div>
                        </div>
                        
                    </div> */}
                    {/* <div className="row justify-content-center py-4">
                        <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div>
                        <div className="col-md-1">
                            <div className="text-center p-0"><strong style={{fontSize: "25px"}}>or</strong></div>
                        </div>
                        <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div>  
                        
                    </div> */}
                        <LoginForm redirect={redirect_to}/>     
                </div>

            </div>
            <Footer />
        </>
     );
}
 
export default Login;