import CustomAlert from "./CustomAlert";
import React, {useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import $ from 'jquery';
import UserAuth from "../../utils/UserAuth";
import { closeModal, triggerLoad, stopLoading } from "../../utils/generic";

const RegisterForms = (props) => {

    
    const [formData, setFormData] = useState({
        firstName: "",
        lastName:"",
        email:"",
        password:""

    })
    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        color: ""
    })
    const navigate = useNavigate();

    const [cookies, setCookies] = useCookies(['user']);

    const loginBtnRef = useRef();

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
        
    }


    const  submitForm = async (ev) => {
        const btn = ev.target;
        const btnLoading = triggerLoad(btn);
        const post = await fetch(window.$base_url+"/api/signup", {
            method: 'POST',
            body: JSON.stringify({
                "email":formData.email,
                "password":formData.password,
                "firstname":formData.firstName,
                "lastname":formData.lastName
            }),
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
                "Accept": "*/*",

            }
        });
        const feedback = await post.json();

        if('token' in feedback) {
            setCookies(
                'user',
                {
                    token: feedback.token,
                    data: feedback.user,
                    isVerified: feedback.is_verified,
                    userType: feedback.user_type

                }, 
                {
                    path: "/", // available accross this application
                    maxAge: 86400 //1 day
                }
            );
            if(props.redirect == null){
                navigate('/courses')
            }else{
                navigate('/'+props.redirect)
            }
            stopLoading(btn, btnLoading);
          
        } else {
            setAlertData({
                title: "oops!",
                message: feedback.error,
                color: "danger"
            })
            stopLoading(btn, btnLoading);

        }

     
    }
    
    $('.cust-eye-span').on('click', function(event){
    
        let targetIcon = $('.password-reveal');
    
        let passwordInput = $('.password');
        console.log(passwordInput.attr('type'));
        if(passwordInput.attr('type')==='password'){
            targetIcon.removeClass('fa-eye-slash').addClass('fa-eye');
            passwordInput.attr('type', 'text');
            event.stopImmediatePropagation();
        }
        else{
            targetIcon.removeClass('fa-eye').addClass('fa-eye-slash');
            passwordInput.attr('type', 'password');
            event.stopImmediatePropagation();
        }
    })

    return ( 
        <>
        
            <CustomAlert  data={alertData}/>
           
            <form action="" className="px-3">
                <div className="row justify-content-center">
                    <div className="col-sm-8 col-md-4 col-lg-3">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control py-3 cust-form-control " 
                            placeholder="First Name"
                            onChange={handleChange} 
                            aria-label="Username"
                            name="firstName"
                            value={formData.firstName}
                            required />
                             <span className="input-group-text  "><i className="fa fa-user  "  ></i></span>
                        </div>
                    </div>
                    <div className="col-sm-8 col-md-4 col-lg-3">
                        <div className="input-group mb-3">
                            <input type="text" 
                            className="form-control py-3 cust-form-control" 
                            placeholder="Last Name"
                            onChange={handleChange} 
                            aria-label="Username"
                            name="lastName"
                            value={formData.lastName}
                            required />
                             <span className="input-group-text  "><i className="fa fa-user-plus  "  ></i></span>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-8 col-md-8 col-lg-6">
                        <div className="input-group mb-3">
                            <input type="email" 
                            className="form-control py-3 cust-form-control" 
                            placeholder="Email Address"
                            onChange={handleChange} 
                            aria-label="Username"
                            value={formData.email}
                            name="email"
                            />
                             <span className="input-group-text  "><i className="fa fa-envelope "  ></i></span>
                        </div>
                    </div>
                </div> 
                <div className="row justify-content-center">
                    <div className=" col-sm-8 col-md-8 col-lg-6">
                        <div className="input-group mb-3">
                            <input type="password" 
                            className="form-control py-3 cust-form-control password"
                            id="passwordInput" 
                            placeholder="Password" 
                            onChange={handleChange}
                            aria-label="Username" 
                            value={formData.password}
                            name="password"
                            required />
                            <span className="input-group-text  cust-eye-span"><i className="fa fa-eye-slash  password-reveal"  ></i></span>
                        </div>
                    </div>
                </div> 
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-sm-8" >
                        <small className="form-check-label " htmlFor="flexCheckDefault">
                            By clicking, I agree to Stormcell's <Link to="#"><strong className="text-primary">Terms of Use Privacy Policy</strong></Link> and to receive promotional material from Stormcell.
                        </small>
                    </div>
                </div>
                <div className="col-12 text-center py-3">
                    <button type="button" className="academy-btn btn-secondary p-md-btn border-1 border-secondary" id="signUpBtn"  onClick={submitForm}>Register</button>
                </div>
            </form> 
        </>
    );
}
 
export default RegisterForms;