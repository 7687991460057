import React from "react";

const ClassList =(props)=>{

    return(
        <div className="col-lg col-sm-6 col-10 my-3">
        <div className="card h-100 custom-animate border-0 min-height stormcell-shadow border-radius">
            <div className="card-body">
                <div className="heading-2 text-center text-capitalize">
                    <div className="custom-wrapper">
                        <div className="custom-img">
                            
                            <img src={props.image} width="50" alt="" />
                        </div>
                    </div>
                    <div className="custom-title">{props.title}</div>
                </div>
                <p className="paragraph-2 text-center text-wrap py-1">
                {props.description}</p>
            </div>
        </div>
    </div>
    );
    
}

const Classes =()=> {
    const classesList = [
        {
         id:1,
         image:"assets/images/icons/mortarboard.png",
         title: 'Physical Classes',
         description: 'This is where real happens. Trust us to help you make a breakthorugh with our engaging and vibrant physical classes.'
        },
        {
         id:2,
         image: "assets/images/icons/machine-learning.png",
         title: 'Virtual Classes',
         description: 'Virtual classes not only obey your command of distance and time but also a level of satisfaction. Join our highly interactive online classes. Enjoy Learning in space!'
        },
        {
        id:3,
        image: "assets/images/icons/think-out-of-the-box.png",
        title: 'Self Paced',
        description: 'Learn from documented professionals in your field of study. This is the most convenient class for you. Follow your tutor step by step to become a pro!'
        }
    ]
    return(
        <section id="info-section-settings" className="categories-section shadow-lg">
        <div className="container"></div>
        <div className="container">
            <div className="wrapper">
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="row justify-content-center">
            {classesList.map((classes) => {
            return (
              <ClassList
              key ={classes.id}
              image = {classes.image}
              title = {classes.title}
              description = {classes.description}
              />
            )
          })}

            </div>
        </div>
    </section>


    );
}

export default Classes;