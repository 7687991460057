import React, { useState } from "react";

export default function StarIcon(props){
    const [starClass, setStarClass] = useState(props.starClass);
    const [starType, setStarType] = useState(props.starType);

    return(
        <>
            <i className={`fa-${starType} ${starClass}`}></i>
        </>
        
    );
}