export function createStars(rate){
    let nStars = [];
    for(let i=0; i < 5;  i++){
        if(nStars.length < 5 ){
            if(rate <= i){
                nStars.push({
                    icon:"fa-star",
                    type:"regular"
                })
            }else if(rate > i && rate < i+1){
                // nStars.push(<StarIcon key={i} starClass="fa-star-half-stroke" starType="solid"/>)
                nStars.push({
                    icon:"fa-star-half-stroke",
                    type:"solid"
                })
            }else{
                // nStars.push(<StarIcon key={i} starClass="fa-star" starType="solid"/>)
                nStars.push({
                    icon:"fa-star",
                    type:"solid"
                })
            }
        }
        
    }
    // setStars(nStars);
    return nStars;
}