import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import CustomAlert from "./CustomAlert";
import $ from 'jquery'
// import { setUserCookie } from "../../utils/stormcell_auth";




const TutorLoginForm = ()=> {
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword ] = useState();
    const [user_type, setUserType ] = useState('tutor');
    const [cookies, setCookies] = useCookies(['user']);

    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        color: ""
    });
    console.log(cookies.user)
    // console.log(cookies.user)

    async function fetchUser(details) {
    
        return fetch(window.$base_url+'/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${process.env.REACT_APP_APP_TOKEN}`
            },
            body: JSON.stringify(details)
        })
        .then(data => data.json())
        .catch(err=>{
            alert('error')
            // console.log(err)
        })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await fetchUser({
            email: email,
            password:password,
            user_type: 'tutor'
        });

        // alert(email)
        // return;

        if('token' in response) {
            setCookies(
                'user',
                {
                    token: response.token,
                    data: response.user,
                    isVerified: response.is_verified,
                    userType: response.user_type

                }, 
                {
                    path: "/", // available accross this application
                    maxAge: 86400 //1 day
                }
            );
            navigate('/tutor/dashboard')
        } else {
            setAlertData({
                title: "oops!",
                message: response.error,
                color: "danger"
            })

        }
        
    }

    function toggleAlert(){
        setAlertData({
            title: "",
            message: "",
            color: ""
        })
    }
    $('.cust-eye-span').on('click', function(event){
    
        let targetIcon = $('.password-reveal');
    
        let passwordInput = $('.password');
        console.log(passwordInput.attr('type'));
        if(passwordInput.attr('type')==='password'){
            targetIcon.removeClass('fa-eye-slash').addClass('fa-eye');
            passwordInput.attr('type', 'text');
            event.stopImmediatePropagation();
        }
        else{
            targetIcon.removeClass('fa-eye').addClass('fa-eye-slash');
            passwordInput.attr('type', 'password');
            event.stopImmediatePropagation();
        }
    })

    return(
        <>      
            <CustomAlert data={alertData}/>
            <form action="" noValidate onSubmit={handleSubmit} className="px-3">
                <div className="row justify-content-center">
                    <div className="col-sm-6 col-md-8 col-lg-6">
                        <div className="input-group mb-3">
                            <input type="email" 
                            className="form-control py-3 cust-form-control" 
                            placeholder="Email Address"
                            autoComplete="Email"
                            onChange={e => setEmail(e.target.value)}
                            aria-label="Email"
                            name="email"
                            />
                            <span className="input-group-text "><i className="fa fa-envelope " ></i></span>
                        </div>
                    </div>
                </div> 
                <div className="row justify-content-center">
                    <div className=" col-sm-6 col-md-8 col-lg-6">
                        <div className="input-group mb-3">
                            <input type="password" 
                            id="passwordInput"
                            className="form-control py-3 cust-form-control password" 
                            placeholder="Password" 
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                            aria-label="Username" 
                            name="password"
                            required />
                            <span className="input-group-text  cust-eye-span"><i className="fa fa-eye-slash password-reveal" ></i></span>
                        </div>
                    </div>
                </div>   
                {/* <small className="form-check-label" htmlFor="flexCheckDefault">
                    By clicking, I agree to Stormcell's <Link to="#"><strong className="text-primary">Terms of Use Privacy Policy</strong></Link> and to receive promotional material from Stormcell.
                </small> */}
                
                
                <div className="col-12 text-center py-3">
                    
                    <button type="submit"  className="academy-btn btn-secondary p-md-btn border-1 border-secondary" id="loginBtn">Login</button>
                </div>
            </form> 
        </>
    );
};

export default TutorLoginForm;