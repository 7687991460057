import React, {useState, useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
// import allCourses  from "../../utils/database";

const CourseOutline = (props)=>{

  // const [topics, setTopics] = useState(props.topics);

  // useEffect(() => {
  //   setCourses(allCourses());
  // }, []);

return(
    <>
    <Accordion  defaultActiveKey="0" >
      {props?.topics?.map((topic, index)=>(
        <Accordion.Item eventKey={index} key={topic.id}>
          <Accordion.Header >{topic.topic}</Accordion.Header>
          <Accordion.Body>
            
            {topic.detail?.split("\n").map(function(item, index) {
              return (
                <span key={index}>
                    {item}
                    <br/>
                </span>
              )
            })}
          </Accordion.Body>
        </Accordion.Item>
          
      ))}   
    </Accordion >
    </>
    

  
  ); 
}

export default CourseOutline;