import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Footer=()=>{
   // const [date, setDate] = useState(new Date())
    return (
        <>
             <footer className="bg-footer text-dark" id="footer-settings">
                <div className="container container-footer-spacing">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <ul className="UnorderList-1">
                                        <li className="UnorderList-1-heading">Our Products</li>
                                        <li className="List-item"><Link target="_blank" to="http://naijadomains.com">Naija
                                                Domains</Link></li>
                                        <li className="List-item"><Link target="_blank" to="#">Zamella</Link></li>
                                        <li className="List-item"><Link target="_blank" to="#">Price</Link></li>
                                        <li className="List-item"><Link target="_blank" to="#">Market Place</Link></li>
                                        <li className="List-item"><Link target="_blank" to="#">Gas Center</Link></li>
                                        <li className="List-item"><Link target="_blank" to="#">Templates</Link></li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-6">
                                    <ul className="UnorderList-2">
                                        <li className="UnorderList-2-heading">Top Client Projects</li>
                                        <li className="List-item"><Link target="_blank" to="http://morcrypto-exchange.com">Morypto
                                                Exchange</Link>
                                        </li>
                                        <li className="List-item"><Link target="_blank" to="http://schchat.com">SchChat</Link></li>
                                        <li className="List-item"><Link target="_blank" to="http://quickerclick.com">Quickerclick</Link>
                                        </li>
                                        <li className="List-item"><Link target="_blank" to="http://rifanonlinemarket.com/">Rifan
                                                Online Market</Link>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <ul className="UnorderList-3">
                                        <li className="UnorderList-3-heading">quick Links</li>
                                        <li className="List-item"><Link to="about.html">About us</Link></li>
                                        <li className="List-item"><Link to="contact.html">Contact us</Link></li>
                                        <li className="List-item"><Link to="what.html">What We Do</Link></li>
                                        <li className="List-item"><Link to="meet.html">Stormcell Team</Link></li>

                                    </ul>
                                </div>
                                <div className="col-md-6 col-6">
                                    <ul className="UnorderList-4">
                                        <li className="UnorderList-4-heading">Follow Stormcell</li>
                                        <li className="List-item">
                                            <div className="footer-social-icon">
                                                <span>
                                                    <Link to="#"><i className="fab fa-facebook"></i></Link>
                                                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                                                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                                                    <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                                                    <Link to="#"><i className="fab fa-whatsapp"></i></Link>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyRight-row copyRight-spacing">
                    <div className="container-fliud line"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <ul className="copyRight-style">
                                    <li>Copyright &copy; Stormcell
                                        {/* {(document.write(date.getFullYear()))}  */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;