import React from "react";
import { Link, useNavigate } from "react-router-dom";

const TutorNavbar = ()=> {
    const navigate = useNavigate();

    const Logout = ()=>{
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
        navigate('/login')
    }
    return(
        <>
            <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src="/assets/images/logo/stormcell-logo-vect.svg" className="stormcell-logo" alt="" />
                </Link>
                
                {/* <!-- <a className="navbar-toggler border-0 third-button " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                   
                    <div className="menu-icon-bg">
                        <div className="menu-icon3">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                </a> --> */}
                <div className="d-md-none mobile-menu-holder">
                    <span className="nav-menu-mobile-btn"><i className="fa fa-bars"></i></span>
                    
                </div>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
            
                    {/* <div className="row custom-control-form1 mx-lg-4">
                        <input type="search" className="form-control ds-input custom-control-form" id="search-input" placeholder="Search docs..." aria-label="Search docs for..." autoComplete="off" data-bd-docs-version="5.0" spellCheck="false" role="combobox" 
                        aria-autocomplete="list" aria-expanded="false" 
                        aria-owns="algolia-autoComplete-listbox-0" dir="auto"
                         style= {{position: "relative", verticalAlign:"top" }} />

                    </div> */}

                    <ul className="navbar-nav ms-auto mt-2 mt-md-0">
                        <li className="nav-item">
                            <Link className="nav-link stormcell-link" to="/">Home</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link stormcell-link" to="/courses">Courses</Link>
                        </li>

                    </ul>
                    <div className="btn-container">
                    <div className="dropdown">
                        <button className="btn stormcell-primary-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ border:"none" }}>
                            <span><i className="bi bi-person" style={{ fontSize:"25px" }}></i></span>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                             <span className=" dropdown-item" onClick={Logout} style={{ cursor:"pointer" }}>Logout</span>
                        </div>
                    </div>
                       
                    </div>
                </div>
            </div>
        </nav>

    </header>
        </>
    )
}

export default TutorNavbar;