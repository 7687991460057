import React from "react"
import Navbar from '../includes/Navbar';
import Footer from '../includes/Footer';
import Hero from "../includes/Hero";
import Classes from "../includes/Classes";
import Trusted from "../includes/Trusted";
import Testimonials from "../includes/Testimonials";
import Courses from "../includes/Courses";
import { Link } from "react-router-dom";

function Home() {
    
  return(
     <>
        <Navbar />
        <Hero />
        <Classes />
        <section className="about-academy-bg-1">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5">
                        <div className="about-academy-content">
                            <h1 className="about-academy-heading" style={{ fontSize:"45px" }}>We are Ready!</h1> 
                            <h1 className="about-academy-heading" style={{ fontSize:"45px" }}>Are you?</h1>
                            <p className="about-academy-paragraph text-justify">
                            Acquiring a skill is made up of small steps. Little by little, every step builds up your confidence. Stormcell Academy is that anchor that will be there to ensure every step leads to the next. Stormcell Academy comprises physical and virtual classes including self paced learning.
                            </p>
                            <a href="#" className="academy-btn btn-primary p-lg-btn my-5">Join us now</a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="about-academy-img">
                            <img src="/assets/images/background/remember-design.png" className="img-fluid" alt="Model 2" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        <Trusted />
        <div className="course py-5">
            <div className="serve pt-5">
                <div className="container text-center py-3">
                        <h2>Take a Bold Action, Choose your Course!</h2>
                    <p className="text-dark">
                        Get on with the positivity.<br />This will take you to success.</p>
                </div>
            </div>
            <Courses userType='learner' items={5}/>
            <div className="text-center pt-5">
                <Link to="/courses" className="btn-primary academy-btn p-lg-btn">View All Courses</Link>
            </div>
        </div>

        <section className="about-academy-bg-1">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div className="about-academy-img">
                            <img src="/assets/images/background/michael-design.png" className="img-fluid" alt="model 1" />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="about-academy-content">
                            <h1 className="about-academy-heading" style={{ fontSize:"45px" }}>Where Real Happens</h1>
                            <p className="about-academy-paragraph text-justify">We offer a combination of skills, mentoring, friendship and job connections and when mentoring and teaching go hand-in-hand, our students come out at the top.  We immerse our students in purpose-driven learning steps, innovative teaching and one-on-one attention. Hence, Stormcell Academy graduates are highly collaborative icons prepared to drive the world to positivity.
                            </p>
                            <a href="#" className="academy-btn btn-primary p-lg-btn my-5">Join us now</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        <Testimonials />
        <Footer />
    </>
  ); 
   
  
}

export default Home;


