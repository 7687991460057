import React from "react";
import {Link} from "react-router-dom";
export default function Hero(){
    return (
        <section className="hero-section">
            <div className="container stormcell-container">
                <div className="row py-md-3 pt-5 pt-md-0">
                    <div className="col-md-6">
                        <div className="stormcell-hero-container">
                            <div className="stormcell-course-title-container mt-lg-5">
                                <h1 className="stormcell-course-heading">Belong Here,<br className="d-md-none" /> Become a Pro!</h1>
                                <p className="stormcell-course-title">Explore the possibilities of learning.</p>
                            </div>
                            <div className="btn-padding">
                                <Link to="/courses" className="btn-gray academy-btn p-md-btn ">Get Started <i className="bi bi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="stormcell-course-img d-none d-md-block ">
                            <img src="/assets/images/background/hero.png" className="hero-img" alt="hero" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
      
}