import React, {useState, useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
// import { PayPalButton } from "react-paypal-button-v2";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";


import $ from 'jquery';

function PaymentAccordion({course, token, user, userBillingCountry}) {
    const navigate = useNavigate();
    // const publicKey = "pk_test_71e27a5af818d16df69d8f3bd6e56498549ba67d"

    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
    // console.log(typeof((parseFloat(course.discounted_price) * 100*parseFloat(userBillingCountry.exchange_rate))))
   
    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(`${user.frstname} ${user.lastname}`);
    const [phone, setPhone] = useState(user.phone);
    const [billingCountry, setBillingCountry] = useState(userBillingCountry);
    const [paymentId, setPaymentId] = useState(null);
    const [courseData, setCourseData] = useState(course);
    const [amount, setAmount] = useState(parseFloat(courseData.discounted_price) * 100 *parseFloat(userBillingCountry.exchange_rate));
    // const paystackBtn = useRef();
    const reference = (Math.random() + 1).toString(36).substring(2)+ Date.now();
    const style = {"layout":"vertical"};
    const currency = "USD";

    // Custom component to wrap the PayPalButtons and handle currency changes
    // const ButtonWrapper = ({ currency, showSpinner }) => {
    //     // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    //     // This is the main reason to wrap the PayPalButtons in a new component
    //     const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    //     useEffect(() => {
    //         dispatch({
    //             type: "resetOptions",
    //             value: {
    //                 ...options,
    //                 currency: currency,
    //             },
    //         });
    //     }, [currency, showSpinner]);

    //     return (<>
    //             { (showSpinner && isPending) && <div className="spinner" /> }
    //             <PayPalButtons
    //                 style={style}
    //                 disabled={false}
    //                 forceReRender={[amount/100, currency, style]}
    //                 fundingSource={undefined}
    //                 createOrder={(data, actions) => {

    //                     return actions.order
    //                         .create({
    //                             purchase_units: [
    //                                 {
    //                                     amount: {
    //                                         currency_code: currency,
    //                                         value: amount,
    //                                     },
    //                                 },
    //                             ],
    //                         })
    //                         .then((orderId) => {
    //                             // createPayment(amount, orderId);
                                
    //                             return orderId;
    //                         });
    //                 }}
    //                 onApprove={function (data, actions) {
    //                     return actions.order.capture().then(function () {
    //                         // enrol(course.id, paymentId);
    //                         // Your code here after capture the order
    //                     });
    //                 }}
    //             />
    //         </>
    //     );
    // }


    const componentProps = {
        email,
        amount,
        metadata: {
          name,
          phone,
          reference
        },
        publicKey,
        reference,
        text: "Pay with Paystack",
        onSuccess: ({ reference }) =>{
           return verifyPayment(reference);
            //  navigate('/payment-success')
        },
        onClose: () => alert("Are you sure you want to terminate this transaction?"),
    }

    

    const createPayment = async (amount, reference) => {
       
        const requestData = {
            'reference': reference,
            'amount': amount/100,
            'description': `Course Purchase payment for ${course.title}`
        }

        return fetch(window.$base_url+ "/api/payments/create/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData),
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data)
            let actualData = data;
            if(actualData.status === 'success'){
                if(courseData.discounted_price > 0){
                    if(billingCountry.currency_code == 'NGN'){
                        document.querySelector('.paystackBtn').click();
                    }else{
                        setPaymentId(data.id);
                        // return ;
                    }
                }else{
                    enrol(course?.id, data?.id);
                }
               
                
            }else{
                alert(actualData.error);
            }
        })
        .catch((err) => {
            alert(err);
        });
    }

    const verifyPayment = async (ref) => {

        const requestData = {
            'reference': ref
        }
        return await fetch(window.$base_url+ "/api/payments/verify/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData),
        })
        .then((res) => res.json())
        .then((data) => {
            let actualData = data.data;
            if(data.status === 'success'){
                enrol(course.id, data?.payment_id);
               
            }else{
                alert(data.error);
            }
        })
        .catch((err) => {
            alert(err);
        });
    }

    const enrol = async (course_id, payment_id)=>{
        const requestData = {
            'course_id': course_id,
            'payment_id': payment_id
        }
        return await fetch(window.$base_url+ "/api/courses/enrol/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestData),
        })
        .then((res) => res.json())
        .then((data) => {
            let actualData = data.data;
            if(data.status === 'success'){
                navigate('/payment-success');
            }else{
                alert(data.error);
            }
        })
        .catch((err) => {
            alert(err);
        });
        
    }

    useEffect(()=>{
        setBillingCountry(userBillingCountry);
        setAmount(parseFloat(courseData.discounted_price) * 100 *parseFloat(userBillingCountry.exchange_rate));
       
    }, [userBillingCountry]);


    useEffect(()=>{
        const newCourse = {...course}
        setCourseData(newCourse);
        console.log(course)
    }, [course]);

    useEffect(()=>{
        setAmount(parseFloat(courseData.discounted_price) * 100 *parseFloat(userBillingCountry.exchange_rate));
    }, [courseData])


    return (
        course.discounted_price > 0 ?
        <Accordion>
            <Accordion.Item eventKey="0" className={billingCountry.currency_code !=="NGN" && 'd-none'}>
                <Accordion.Header>
                    <img src="/assets1/image/paystack-2.svg" alt="" style={{height:"50px", width: "130px"}} />
                </Accordion.Header>
                <Accordion.Body>
                <div className="accordion-body custom-accordion-body bg-light text-center">
                    <img className="py-4" src="/assets1/image/paystack-cards.png" alt="" style={{height: "100%", width: "80%"}} />
                    <div className="row py-3 justify-content-center"></div>
                
                    <div className="py-3 justify-content-center">
                    <PaystackButton className="paystack-button btn custom-pay-button d-none paystackBtn" style={{width: "200px"}} type="button" {...componentProps} />
                    <button type="button" className="btn custom-pay-button " style={{width: "200px"}} onClick={()=>{{ 
                        createPayment(amount, reference)
                    }}}>Pay with Paystack</button>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1"  className={billingCountry.currency_code !=="USD" && 'd-none'}>
                <Accordion.Header> 
                    <img src="/assets1/image/paypal.png" alt="" style={{height: "40px", width: "150px"}} />
                </Accordion.Header>
                <Accordion.Body>
                <div className="accordion-body custom-accordion-body text-center">
                    
                    <div className="row py-3 justify-content-center">
                    
                        <PayPalScriptProvider
                            options={{
                                "client-id": "test",
                                components: "buttons",
                                currency: "USD"
                            }}
                        >
                            <ButtonWrapper
                                currency={"USD"}
                                showSpinner={false}
                            />
                        </PayPalScriptProvider>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item> */}
        </Accordion>
        :
        <button  className="academy-btn btn-primary p-md-btn border-primary" onClick={()=>{{ 
            createPayment(0, reference)
        }}}>Complete Your Free Enrollment {">>>"} </button>
    );
}

export default PaymentAccordion;