import React, {useState, useEffect} from "react";
export default function CountryList({userBillingCountry, setUserBillingCountry}) {

    const [countries, setCountries] = useState([]);
    
    


    async function getUserIP(){
      fetch(window.$base_url+ '/api/get-user-ip' , {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
        }
      })
        .then((response) => response.json())
        .then((data) => {

          getUserCountry(data.ip)
          // console.log(countryData[0].name);
        })
        .catch((err) => {
          alert("error");
        });
    }

    async function getUserCountry(ip){
      fetch('http://www.geoplugin.net/json.gp?ip='+ip , {
        method: "GET"
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.geoplugin_status >= 200 && data.geoplugin_status < 300){
            setUserBillingCountry({
              name: data.geoplugin_countryName,
              currency_code: data.geoplugin_countryName === "Nigeria" ? data.geoplugin_currencyCode:"USD",
              exchange_rate: data.geoplugin_countryName === "Nigeria" ? data.geoplugin_currencyConverter + 300 : 1
            });
            fetchCountries();
          }
          // console.log(countryData[0].name);
        })
        .catch((err) => {
          alert("error");
        });
    }
    async function fetchCountries() {
        return fetch(window.$base_url+ '/api/countries' , {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
          },
          body: JSON.stringify(),
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            let countryData = data.data;
            setCountries(countryData);
            // console.log(countryData[0].name);
          })
          .catch((err) => {
            alert("error");
          });
    }

    const getexchangeRate = (symbol, target, selectedCountry)=>{
      return fetch(window.$base_url+ '/api/convert/'+symbol+'/USD' , {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
        },
        body: JSON.stringify(),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserBillingCountry({
            name: target.value ,
            currency_code: target.value === "Nigeria" ? selectedCountry.getAttribute("currency-symbol"):"USD",
            exchange_rate: target.value === "Nigeria" ? parseFloat(data.rate) + 300 : 1
          });
          // return data.rate
          // console.log(countryData[0].name);
        })
        .catch((err) => {
          alert("error");
        });
    }

    const updateBillingCountry = (target, selectedCountry)=>{
      if( target.value === "Nigeria"){
        getexchangeRate(selectedCountry.getAttribute("currency-symbol"), target, selectedCountry);
        
      }else{
        setUserBillingCountry({
          name: target.value ,
          currency_code: "USD",
          exchange_rate: 1
        });
      }
    }

    // useEffect(() => {
    //   getUserIP(); 
    // }, []);

    useEffect(()=>{
      fetchCountries();
      console.log(userBillingCountry.name == "Nigeria" ? userBillingCountry.exchange_rate:1)
    }, [userBillingCountry]);

     

    return (
        <>
        <select className="form-select form-select-lg mb-3 cust-form-select" aria-label=".form-select-lg example" onChange={
          (e)=>{
            // console.log(e.target.options[ e.target.selectedIndex ].getAttribute("currency-symbol"))
            const selectedCountry = e.target.options[ e.target.selectedIndex ];
            updateBillingCountry(e.target, selectedCountry)
          }
        }>
          <option value={userBillingCountry.name}>{userBillingCountry.name}</option>
            {countries.map((countryInstance, index)=>(
                <option value={countryInstance.name} key={countryInstance.id} currency-symbol={countryInstance.payment_currency}>{countryInstance.name}</option>
            ))}
        </select>
     </>
    )
}

