import React, {useEffect, useState} from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import CountryList from '../includes/CountryList';
import Footer from '../includes/Footer';
import Navbar from '../includes/Navbar';
import PaymentAccordion from '../includes/PaymentAccordion';
import { useCookies } from 'react-cookie';


const PaymentDetail = () => {

    const [cookies, setCookies] = useCookies(['user']);
    const [course, setCourse] = useState({});
    const [userBillingCountry, setUserBillingCountry] = useState({
        currency_code:"NGN",
        name: "Nigeria",
        exchange_rate: 730

    });
    const {slug} = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const loginRedirect = searchParams.get("method") === null ? `login?redirect_to=courses/${slug}/enrol` : `login?redirect_to=courses/${slug}/enrol?method=${searchParams.get("method")}`;

    const registrationRedirect = searchParams.get("method") === null ? `register?redirect_to=courses/${slug}/enrol` : `register?redirect_to=courses/${slug}/enrol?method=${searchParams.get("method")}`

    async function fetchCourse() {
        return fetch(window.$base_url+ "/api/courses/" + slug, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
          },
          body: JSON.stringify(),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            let actualData = data.data;
            setCourse(actualData);
            // console.log(actualData);
          })
          .catch((err) => {
            alert("error");
          });
    }
  
    useEffect(()=>{
        fetchCourse();
    }, []);
    
    return (  
        <>
            <Navbar />
            <div className="course  main-container">
                <div className="container ">
                    <div className="row ">
                        <div className="col-md-7 p-5">
                            <h2>Checkout</h2>
                            <div className="row">
                                <h5 className="pt-3 pb-3"style={{fontWeight: "500px"}} >Order Summary ( {course.discounted_price ? 
                                        <strong className="custom-original-price">{(parseFloat(course.discounted_price)*parseFloat(
                                            userBillingCountry.name == "Nigeria" ? userBillingCountry.exchange_rate:1
                                        )).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong> 
                                        : 0
                                    }
                                    <strong className="currency-code ms-1">{userBillingCountry.currency_code}</strong>)
                                </h5>
                                <strong><img src={window.$base_url+'/storage/'+course.cover_photo} style={{width: "50px"}} alt="" /> {course.title}</strong>
                            </div>
                            <h4 className="pt-5" style={{fontSize: "20px"}}>Billing Address</h4>
                            <div className="row">
                                <label htmlFor="basic-url" className="form-label">Country</label>
                                <div className="col-6">
                                    <CountryList userBillingCountry={userBillingCountry} setUserBillingCountry={setUserBillingCountry} />
                                   
                                </div>
                            </div>
                            {course.discounted_price > 0 &&
                                <div className="row pt-4">
                                    <div className="col-6"><h4>Payment Method</h4></div>
                                    {cookies.user && <div className="col-6 text-right">Secured Connection <i className="fa fa-lock"></i></div> }
                                </div>
                            }
                            { typeof cookies.user == 'undefined' ?

                                <>
                                    <strong className='text-danger'>You must be logged in to complete your enrollment</strong>  <br /><br />

                                    <Link to={`/${loginRedirect}`} className="academy-btn btn-primary p-md-btn">Login </Link>
                                    &nbsp;&nbsp;
                                    OR
                                    &nbsp;&nbsp;
                                    <Link to={`/${registrationRedirect}`} className="academy-btn btn-secondary p-md-btn">Sign Up</Link>
                                </>
                                
                                :
                                    <PaymentAccordion user = {cookies.user.data} token={cookies.user.token} course = {course} userBillingCountry={userBillingCountry}/>

                            }
                           
                            
                        </div>
                        <div className="col-md-5 bg-light px-lg-5  custom-summary-col">
                            <h5 className="custom-summary ">Summary</h5>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-7">
                                            <span className="custom-original-price">Original Price</span>
                                        </div>
                                        <div className="col-5 text-right">
                                            {course.original_price ?
                                            <span className="custom-original-price">{(parseFloat(course.original_price)*parseFloat(
                                                userBillingCountry.name == "Nigeria" ? userBillingCountry.exchange_rate:1
                                            )).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                            : 0
                                            }
                                            <span className="currency-code ms-1">{userBillingCountry.currency_code}</span>
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7">
                                            <span className="custom-original-price">Discount</span>
                                        </div>
                                        <div className="col-5 text-right">
                                        {course.original_price ?
                                            <span className="custom-original-price"> 
                                                { 
                                                    ( 
                                                        parseFloat(course.original_price) * parseFloat(
                                                            userBillingCountry.name == "Nigeria" ? userBillingCountry.exchange_rate:1
                                                        ) - 
                                                        parseFloat( course.discounted_price) * parseFloat(
                                                            userBillingCountry.name == "Nigeria" ? userBillingCountry.exchange_rate:1
                                                            )
                                                    ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                            </span>
                                            : 0
                                        }
                                         <span className="currency-code ms-1">{userBillingCountry.currency_code}</span>
                                        </div>
                                    </div>
                                    <hr />
                                   
                                    <div className="row p-3">
                                        <button type="submit" className="btn-secondary stormcell-secondary-btn p-3 border-secondary" style={{ cursor:"not-allowed" }}>Total: {course.discounted_price ? 
                                                <strong className="custom-original-price">{(parseFloat(course.discounted_price)*parseFloat(
                                                    userBillingCountry.name == "Nigeria" ? userBillingCountry.exchange_rate:1
                                                )).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong> 
                                             : 0
                                            }
                                            <strong className="currency-code ms-1">{userBillingCountry.currency_code}</strong>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <p className="custom-original-price1">By completing your purchase you agree to this <Link to="">terms and condition.</Link></p>
                                        <p className="custom-original-price1">stormcell is required by law to collect applicable taxes for purchases made in certain tax jurisdictions. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
 
export default PaymentDetail;