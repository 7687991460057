import React, {useState, useEffect} from "react";
import SideNav from '../includes/SideNav';
import PurchasedCourses from "../includes/PurchasedCourses";
import Footer from "../includes/Footer";
import TutorNavbar from "../includes/TutorNavbar";
import { useCookies } from "react-cookie";


const UserDashBoard =() =>{
    const [cookies, setCookies] = useCookies(['user']);
    const [enrollments, setEnrollments] = useState({
        all:0,
        ongoing:0,
        completed:0
    })

    ; 
    const getEnrollmentCounts = async ()=>{
        return fetch(window.$base_url+ "/api/courses/enrollment-count", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.user.token}`,
            },
            })
            .then((res) => res.json())
            .then((data) => {
                let actualData = data.data;
        
                setEnrollments(actualData);
                // console.log(actualData);
            })
            .catch((err) => {
                alert(err);
            });
    }

    useEffect(()=>{
        getEnrollmentCounts();
    }, [])
    return (
        <>
            <div className="classroom main-container custom-upload-container">
                <div className="row px-lg-5 px-2 justify-content-center ">
                    <TutorNavbar />
                    <div className="">
                        <SideNav />
                    </div>
                    <div className="col-xl-9">

                        <div className="page-body col-sm-12" style={{backgroundColor: "white"}}>
                            <div className="courses  my-1">
                                <div className="courses-heading my-2 col-md-12 col-sm-6 mx-auto py-2 px-5">
                                    <h5 className="mx-auto mt-5 ms-3"><small>Welcome</small> {cookies.user.data.firstname}</h5>
                                </div>

                                <div className="courses-status row d-flex justify-content-evenly px-sm-3 px-lg-1">
                                    
                                    <div className="purchased col-lg-3 col-sm-4 col-8 mb-5">
                                        <div className="course-status-top col-sm-12 py-md-3 d-flex flex-row rounded-top border border-bottom-danger " 
                                        style={{background: "#FFFFFF", boxShadow: "2px 2px 20px rgba(15, 24, 49, 0.247)"}}>
                                            <div className="courses-inner-icon my-auto ms-2"><span className="input-group-text  custom-icon-circle"><i className="bi bi-book"></i></span><span className="txt">All courses</span></div>
                                            
                                        </div>
                                        <div className="courses-status-bottom  col-sm-12 rounded-bottom" style={{backgroundColor: "rgba(244, 247, 248, 0.925)"}}>
                                            <div className="rounded-colour border first rounded-circle mx-auto">
                                                <p className="course-number text-light pb-4 ">{enrollments.all}</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="purchased col-lg-3 col-sm-4 col-8 mb-5">
                                        <div className="course-status-top col-sm-12 py-md-3 d-flex flex-row rounded-top border border-bottom-danger" style={{background: "white",
                                            boxShadow: "2px 2px 20px rgba(15, 24, 49, 0.247)"}}>
                                            <div className="courses-inner-icon my-auto ms-2"><span className="input-group-text  custom-icon-circle"><i className="bi bi-book"></i></span><span className="txt">Ongoing Enrolments</span></div>
                                            
                                        </div>
                                        
                                        <div className="courses-status-bottom  col-sm-12 rounded-bottom" style={{backgroundColor: "rgba(244, 247, 248, 0.925)"}}>
                                            <div className="rounded-colour border second rounded-circle mx-auto">
                                                <p className="course-number text-light">{enrollments.ongoing}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="purchased col-lg-3 col-sm-4 col-8 mb-5">
                                        <div className="course-status-top col-sm-12 py-md-3 d-flex flex-row rounded-top border border-bottom-danger " style={{background: "white",
                                            boxShadow: "2px 2px 20px rgba(15, 24, 49, 0.247)"}}>
                                            <div className="courses-inner-icon my-auto ms-2 " ><span className="input-group-text  custom-icon-circle"><i className="bi bi-book"></i></span><span className="txt">Total Enrolments</span></div>
                                        
                                        </div>
                                        
                                        <div className="courses-status-bottom col-lg-12 col-sm-12 rounded-bottom" style={{backgroundColor: "rgba(244, 247, 248, 0.925)"}}>
                                            <div className="rounded-colour border third rounded-circle mx-auto">
                                                <p className="course-number text-light">{enrollments.completed}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="container mb-5" style={{boxShadow: "0px 97.523px 107.101px rgba(0, 0, 0, 0.233)", marginBottom: "30px", height: "45%"}}>
                            
                                <div className="table-main-head mx-auto col-lg-3 mt-5 fs-3">
                                    <h4>Purchased courses</h4>
                                </div>
                                <PurchasedCourses />
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </div>
            <Footer />
        </>
    )
}

export default UserDashBoard;