import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie";

const PurchasedCourses =()=> {
    const [enrollments, setEnrollments] = useState([]);
    const [cookies, setCookies]  = useCookies(['user']);

    
    const fetchEnrollments = async ()=>{
        await fetch(window.$base_url+'/api/courses/enrolled', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${cookies.user.token}`
            },
            body: JSON.stringify()
        })
        .then((res) => res.json())
        .then((data) => {
            let actualData = (data.data);
            
            setEnrollments(actualData);
            console.log(actualData)
        })
        .catch(err=>{
            console.log(err)
            // alert(err)
        })
    }
    
    useEffect(() => {
        fetchEnrollments();
    }, [])

    return(
        
            <div className="table-responsive-sm p-0 ">
                <table className="table text-center">
                    <thead>
                    <tr className="text-center custom-tr">
                        <th scope="col">Course Title</th>
                        <th scope="col">Status</th>
                        <th scope="col">Classroom Link</th>
                        <th scope="col">Timetable</th>
                    </tr>
                    </thead>
                    <tbody>
                    {enrollments.map((enrollment, index) => (
                        <tr className="text-center" key={index}>
                            <th scope="row">{enrollment.title}</th>
                            <td><span className={`badge bg-${enrollment.status_color}`}>{enrollment.status}</span> </td>
                            <td>
                                {/* <a href="#" target="_blank"></a> */}
                            </td>
                            <td>
                                <div className="row mt-3    ">
                                    <div className="col-4">
                                        <a href="">
                                        <span className="custom-icon-div p-2">
                                            <i className="bi bi-eye text-white"></i>
                                        </span>
                                        </a>
                                    </div>
                                    <div className="col-4">
                                        <a href="">
                                        <span className="custom-icon-div1 p-2">
                                            <i className="bi bi-download text-white "></i>
                                        </span>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            
    )
}

export default PurchasedCourses;