import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useCookies } from "react-cookie";

const TutorAuth = () =>{
   const [cookies] = useCookies(['user']);
    return(
            !cookies.user || cookies.user.userType !== 'tutor'
            ?
            <Navigate to={'/tutor/login'}/>
            : 
            <Outlet />
    )
 
}

// const Authenticate = (data)=>{
//         setCookies(
//                 'user',
//                 {
//                     token: data.token,
//                     data: data.user,
//                     isVerified: data.is_verified,
//                     userType: data.user_type

//                 }, 
//                 {
//                     path: "/", // available accross this application
//                     maxAge: 86400 //1 day
//                 }
//             );
// }

export default TutorAuth;