import React from "react";
//import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import TutorLoginForm from "../includes/TutorLoginForm";
import Navbar from "../includes/Navbar";

const TutorLogin = () => {


    return ( 
        <>
            <Navbar />
            <div className="course  main-container">
                <div className="serve">
                    {/* <div className="row justify-content-center py-3 px-2 mx-2">
                    </div> */}
                    <div className="row justify-content-center py-4">
                        {/* <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div> */}
                        <div className="col-md-12 offset">
                            <div className="text-center p-0"><strong style={{fontSize: "25px"}}>Tutor Login</strong></div>
                        </div>
                        {/* <div className=" d-lg-block d-none col-lg-2">
                            <div className="custom-hr-section"></div>
                        </div>   */}
                        
                    </div>
                        <TutorLoginForm />     
                </div>

            </div>
            <Footer />
        </>
     );
}
 
export default TutorLogin;