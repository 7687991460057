import React, {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { useNavigate } from 'react-router-dom';
const Logout = () => {

    
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();

    // console.log(cookies.user)
    // if(cookies.user != 'undefined'){
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
        
       
    // }

    // function logoutFxn(){
    //     if(cookies.user){
    //         document.cookies = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //         console.log("user logged out!")
    //        window.location.replace('/login')
    //     }
       
    // }
    useEffect(() => {
        navigate('/login')
    }, [])


    return (  
        <>
        <h1>Logged Out</h1>
        </>
    );
}
 
export default Logout;