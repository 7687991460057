import React from 'react';
// import './App.css';
import Home from './components/pages/Home';
import {Routes, Route} from 'react-router-dom';
import CourseDetail from './components/pages/CourseDetail';
import Register from './components/pages/Register';
import Login from './components/pages/Login';
import PaymentDetail from './components/pages/PaymentDetail';
import CoursePage from './components/pages/CoursePage';
import TutorRegister from './components/pages/TutorRegister';
import TutorLogin from './components/pages/TutorLogin';
import { useCookies } from "react-cookie";
import UserAuth from './utils/UserAuth';

import TutorAuth from './utils/TutorAuth';
import EditCourse from './components/pages/EditCourse';
import Logout from './components/pages/Logout';

import Upload from './components/pages/Upload';
import CourseManagement from './components/pages/CourseManagement';
import Dashboard from './components/pages/Dashboard';
import TutorCourses from './components/pages/TutorCourses';
import PaymentSuccess from './components/pages/PaymentSuccess';
import UserDashBoard from './components/pages/UserDashBoard';
import EnrolledCourses from './components/pages/EnrolledCourses';
import PaymentHistory from './components/pages/PaymentHistory';

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  return (

    <Routes>
      {/* Protected User route for learners */}
      <Route exact path='/courses/:slug' element={<CourseDetail />}/>
      <Route path='/courses/:slug/enrol' element={<PaymentDetail />}/>
      <Route element={<UserAuth />}>
        <Route path='/payment-success' element={<PaymentSuccess />}/>
        <Route path='/payment-history' element={<PaymentHistory />}/>
        <Route path='/dashboard' element={<UserDashBoard />}/>
        <Route path='/courses/enrolled' element={<EnrolledCourses />}/>
      </Route>
        {/* Protected User route for Tutors */}
        <Route element={<TutorAuth />}>
        <Route path='/tutor/dashboard' element={<Dashboard />}/>
        <Route path='tutor/courses' element={<TutorCourses />}/>
        <Route path='courses/upload' element={<Upload />}/>
        <Route exact path='/courses/:slug/edit/' element={<EditCourse />}/>
        <Route exact path='/courses/:slug/manage' element={<CourseManagement />}/>
      </Route>
      {/* End of protected routes */}

        
        <Route path='/' element={<Home />}/>
        <Route path='/register' element={<Register />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/tutor/register' element={<TutorRegister />}/>
        <Route path='/tutor/login' element={<TutorLogin />}/>
        <Route path='/logout' element={<Logout />}/>
        <Route path='/courses' element={<CoursePage />}/>
       
        

    </Routes>
    
  );
}

export default App;
