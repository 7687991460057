import React, { useState, useEffect } from "react";
import { ReactDOM } from "react-dom/client";
import TutorNavbar from "../includes/TutorNavbar";
import Footer from "../includes/Footer";
import { Link, useParams } from "react-router-dom";
import SideNav from "../includes/SideNav";
import $, { data } from "jquery";
import { Cookies, useCookies } from "react-cookie";
import CustomAlert from "../includes/CustomAlert";
import CourseService from "../../services/CourseService";

const EditCourse = () => {
  const [courseData, setCourseData] = useState({});
  const [formData, setFormData] = useState({});
  const { slug } = useParams();
  const [cookies, setCookies] = useCookies(["user"]);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [categories, setCategories] = useState([]);
  
  const {fetchCourseCategories} = CourseService();

  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    color: "",
  });

  // const currentCategory

  async function fetchCourse() {
    return fetch(window.$base_url+ "/api/courses/" + slug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
      },
    })
      .then((resp) => resp.json())
      .then((feedbackData) => {
        // console.log(feedbackData)
        // let mainData = feedbackData.data
        let responseData = {
          title: feedbackData.data.title,
          short_description: feedbackData.data.short_description,
          long_description: feedbackData.data.long_description,
          original_price: feedbackData.data.original_price,
          discounted_price: feedbackData.data.discounted_price,
          duration: feedbackData.data.duration,
        };

        setCourseData(responseData);
      });
  }

  function monitorChange(event) {
    const name = event.target.getAttribute("name");

    const value = event.target.value;

    const newFormData = { ...courseData, [name]: value };
    setCourseData(newFormData);
    //console.log(courseData[name]);
  }

  function setCoverImage(event) {
    console.log(event.target);
    const file = event.target.files[0];
    setCoverPhoto(file);
  }

  const handleSubmit = async () => {
    for (const key in courseData) {
      if (courseData[key] == "") {
        setAlertData({
          title: "Attention!",
          message:
            key
              .replace("_", " ")
              .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()) + " is required",
          color: "danger",
        });
        // alert( key.replace('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())+" is required" )
        return;
      }
    }
    const courses = await fetch(window.$base_url+ "/api/courses/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.user.token}`,
      },
      body: JSON.stringify({
        slug: slug,
        fields: courseData,
      }),
    });
    const feedback = await courses.json();

    if (
      feedback.status != "undefined" &&
      feedback.status == "success" &&
      coverPhoto != ""
    ) {
      let uploadData = new FormData();

      uploadData.append("slug", slug);
      uploadData.append("cover_photo", coverPhoto);

      // console.log(uploadData.get('cover_photo'));
      // return;

      const uploadProfilePicture = await fetch(
        window.$base_url+ "/api/courses/update-cover-photo",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.user.token}`,
          },
          body: uploadData,
        }
      );

      const uploadResponse = await uploadProfilePicture.json();
      console.log(uploadResponse);

      if (
        uploadResponse.status != "undefined" &&
        uploadResponse.status == "success"
      ) {
        setAlertData({
          title: "Success!",
          message: "Course updated",
          color: "success",
        });
      } else {
        setAlertData({
          title: "Upload Failed!",
          message:
            feedback.status != "undefined" && feedback.status == "fail"
              ? feedback.error
              : "Something Went Wrong",
          color: "danger",
        });
      }
    } else {
      if (feedback.status != "undefined" && feedback.status == "success") {
        setAlertData({
          title: "Success!",
          message: feedback.message,
          color: "success",
        });
      } else {
        setAlertData({
          title: "Request Failed!",
          message:
            feedback.status != "undefined" && feedback.status == "fail"
              ? feedback.error
              : "Something Went Wrong",
          color: "danger",
        });
      }
    }
  };

  const getCourseService = (resp)=>{
      let results = [];
      if(resp !=null ){
          return resp.then((res) => res.json())
          .then((data) => {
              let actualData = (data.data);
              setCategories(actualData)
          })
          .catch(err=>{
              console.log(err)
          })
      } 
  }

  useEffect(() => {
    fetchCourse();
  }, [formData]);

  useEffect(()=>{
        getCourseService(fetchCourseCategories());

    })

  return (
    <div>
      <TutorNavbar />
      <div className="classroom main-container custom-upload-container">
        <div className="row px-lg-5 px-2 justify-content-center ">
          <div className="">
            {/* <div className="d-md-none mobile-menu-holder">
                            <span className="nav-menu-mobile-btn">
                                <i className="fa fa-bars mr-5"></i>
                            </span>
                        
                        </div> */}
            <SideNav />
          </div>

          <div className="col-xl-9 col-md-10 ">
            <CustomAlert data={alertData} />
            <div className="card bg-white custom-upload-card px-lg-5">
              <h4 className="mt-4 ms-3 custom-upload-heading">Course upload</h4>
              <form>
                <div className="row justify-content-center p-3">
                  <div className="col-sm-6">
                    <div className="input-group custom-group-input my-4">
                      <div>
                        <span className="input-group-text  custom-icon-circle">
                          <i className="bi bi-book"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control py-1 custom-input-circle"
                        placeholder="course title"
                        aria-label="Username"
                        defaultValue={courseData.title}
                        name="title"
                        onChange={monitorChange}
                        onKeyDown={monitorChange}
                        required
                      />
                    </div>
                    <div className=" my-md-5 mb-3  custom-input-textarea">
                      <textarea
                        id=""
                        rows="4"
                        className="custom-textarea form-control "
                        placeholder="write a short description about the course"
                        defaultValue={courseData.short_description}
                        name="short_description"
                        onChange={monitorChange}
                        onKeyDown={monitorChange}
                      ></textarea>
                    </div>
                    <div className="my-md-5 mb-3 custom-input-textarea">
                      <textarea
                        id=""
                        rows="4"
                        className="custom-textarea form-control"
                        placeholder="write a long description about the course"
                        defaultValue={courseData.long_description}
                        name="long_description"
                        onChange={monitorChange}
                        onKeyDown={monitorChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="my-4 mb-3 input-group custom-group-input">
                      <div>
                        <span className="input-group-text  custom-icon-circle">
                          <i className="bi bi-cloud-arrow-up"></i>
                        </span>
                      </div>
                      <input
                        type="file"
                        className="form-control  m-auto custom-input-circle-file"
                        placeholder=""
                        aria-label=""
                        name="cover_photo"
                        onChange={setCoverImage}
                        required
                      />
                    </div>
                    <div className="mt-md-5  mb-3 input-group custom-group-input">
                      <div>
                        <span className="input-group-text  custom-icon-circle">
                          <i className="bi bi-currency-dollar"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control py-1 custom-input-circle"
                        placeholder="Original price"
                        aria-label="Username"
                        defaultValue={courseData.original_price}
                        name="original_price"
                        onChange={monitorChange}
                        onKeyDown={monitorChange}
                        required
                      />
                    </div>
                    <div className="mt-md-5  mb-3 input-group custom-group-input">
                      <div>
                        <span className="input-group-text  custom-icon-circle">
                          <i className="bi bi-currency-dollar"></i>
                        </span>
                      </div>

                      <input
                        type="text"
                        className="form-control py-1 custom-input-circle"
                        placeholder="Discounted price"
                        aria-label="Username"
                        defaultValue={courseData.discounted_price}
                        name="discounted_price"
                        onChange={monitorChange}
                        onKeyDown={monitorChange}
                        required
                      />
                    </div>
                    <div className="mt-md-5 input-group custom-group-input custom-group-input1 ">
                      <div>
                        <span className="input-group-text  custom-icon-circle">
                          <i className="bi bi-hourglass"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control py-1 custom-input-circle"
                        placeholder="Duration (weeks)"
                        aria-label="Username"
                        defaultValue={courseData.duration}
                        name="duration"
                        onChange={monitorChange}
                        onKeyDown={monitorChange}
                        required
                      />
                    </div>
                    <div className="mt-md-5 input-group custom-group-input custom-group-input1 ">
                        <div>
                            <span className="input-group-text  custom-icon-circle"><i className="bi bi-list"></i></span>
                        </div>
                        <select className="form-control py-1 custom-input-circle" name="course_category_id" onChange={monitorChange} required defaultValue={courseData.course_category_id}>
                        {/* <option value={courseData.course_category_id}>{category.name}</option> */}
                            {categories.map((category, index)=>(
                                <option value={category.id}>{category.name}</option>
                            ))}
                        </select>
                        
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center mt-3 mb-4">
                  <button
                    className="btn btn-primary custom-upload-button"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit <i className=""></i>
                  </button>
                </div>
              </form>
            </div>


          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditCourse;
