import React from "react";
import {Link} from "react-router-dom"
const Trusted =()=>{

    return(
        <section className="shadow-sm stormcell-primary-border">
        <div className="container trusted-padding">
            <div className="trusted-heading text-center my-2">Trusted by</div>
            <div className="d-flex justify-content-center trusted-div row" id="wrapper">
                <div className="col-4 col-md t1">
                    <Link to="https://morcrypto-exchange.com" target="_blank"><img src="./assets/images/trusted/morcrypto.png" alt="" /></Link>

                </div>
                <div  className="col-4 col-md t2">
                    <Link to="#" target="_blank"><img src="./assets/images/trusted/gardencity.png" alt="" /></Link>
                </div>
                <div  className="col-4 col-md t3">
                    <Link to="http://schchat.com" target="_blank"><img src="./assets/images/trusted/schchat.png" alt="" /></Link>
                </div>
                <div  className="col-4 col-md t4">
                    <Link to="http://npp.ng" target="_blank"><img src="./assets/images/trusted/planet.png" alt="" /></Link>
                </div>
                <div  className="col-4 col-md t5">
                    <Link to="http://www.bir.by.gov.ng/" target="_blank"><img src="./assets/images/trusted/bir.png" alt="" /></Link>
                </div>
            </div>
            
        </div>
    </section>
    );
}

export default Trusted;