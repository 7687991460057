import React from "react";
import CoursesTable from "../includes/CoursesTable";
import Footer from "../includes/Footer";
import TutorSideNav from "../includes/TutorSideNav";
import TutorNavbar from "../includes/TutorNavbar";

const TutorCourses = ()=>{

    return(
        <>
        <TutorNavbar />
            <div className="classroom main-container custom-upload-container">
        
                <div className="row px-lg-5 px-2 justify-content-center course py-5" id="course" style={{ backgroundColor:"red !important" }}>
                    <div className="">

                       
                        <TutorSideNav />
                    </div>
                   
                
                    <div className="col-xl-9 col-md-10">
                        <CoursesTable userType="tutor"/>
                    </div>
                </div>
            </div>
        <Footer />
        </>
    );
}

export default TutorCourses;