import React from "react";
import { Link, useNavigate} from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Navbar(){

    
    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies(['user']);

    const Logout = ()=>{
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
        navigate('/login')
    }
    // console.log(cookies.user)

    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm py-2 py-lg-0">
                    <div className="container">
                        <Link className="navbar-brand" to="/"><img src="/assets/images/logo/stormcell-logo-vect.svg" className="stormcell-logo" alt="" /></Link>
                        <Link className="navbar-toggler border-0 third-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" to="#">
                           
                            <div className="menu-icon-bg">
                                <div className="menu-icon3">
                                    <span></span><span></span><span></span>
                                </div>
                            </div>
                        </Link>
                       

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item"><Link to="/" className="nav-link stormcell-link">
                                
                                </Link>
                                </li>
                            </ul>
                            
                            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                                {/* <li className="nav-item">
                                    <Link className="nav-link stormcell-link" to="/">Stormcell Academy </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link stormcell-link" to="/courses">Courses</Link>
                                </li>
                                {typeof cookies.user != 'undefined' &&
                                    <li className="nav-item mt-2 mt-lg-0">
                                        {
                                            cookies.user.userType == "tutor"
                                            ?
                                             <Link to="/tutor/dashboard" className="academy-btn btn-primary p-sm-btn me-1">Dashboard</Link>
                                            : 
                                             <Link to="/dashboard" className="academy-btn btn-primary p-sm-btn me-1">Dashboard</Link>
                                        }
                                           
                                    </li>
                                }

                            </ul>
                            <div className="btn-container">
                                {typeof cookies.user != 'undefined' ?
                                <>
                                    
                                     <div className="dropdown">
                                        <button className="btn stormcell-primary-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ border:"none" }}>
                                            <span><i className="bi bi-person" style={{ fontSize:"25px" }}></i></span>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <span className=" dropdown-item" onClick={Logout} style={{ cursor:"pointer" }}>Logout</span>
                                        </div>
                                    </div>
                                </>
                                :   
                                <div className="py-3">
                                    <Link to="/login" className="academy-btn btn-secondary p-sm-btn me-1">Login<i className="bi bi-lock-fill ps-2"></i></Link> 
                                    <br className="d-lg-none" />
                                    <br className="d-lg-none" />
                                    <Link to="/register" className="academy-btn btn-gray p-sm-btn border-primary border-2 mt-2 mt-lg-0 mb-2 mb-lg-0">Signup<i
                                            className="bi bi-arrow-right-circle-fill ps-2"></i></Link>
                                            {/* <br className="d-lg-none" />
                                    <br className="d-lg-none" /> */}
                                </div>
                                    
                                }
                                
                            </div>
                        </div>
                    </div>
                </nav>

            </header>
        </>
    );
}