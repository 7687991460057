import CustomAlert from "./CustomAlert";
import React, {useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import $ from 'jquery';


const TutorRegisterForms = () => {

    const [formData, setFormData] = useState({
        user_type:"tutor",
        firstName: "",
        lastName:"",
        email:"",
        password:""
    })
    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        color: ""
    })
    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies(['user']);

    

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
        
    }
    

    const  submitForm = async () => {
    
        const post = await fetch(window.$base_url+"/api/signup", {
            method: 'POST',
            body: JSON.stringify({
                "user_type":formData.user_type,
                "email":formData.email,
                "password":formData.password,
                "firstname":formData.firstName,
                "lastname":formData.lastName
            }),
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${process.env.REACT_APP_APP_TOKEN}`,
                "Accept": "*/*",

            }
        });
        const feedback = await post.json();

        if('token' in feedback) {
            setCookies(
                'user',
                {
                    token: feedback.token,
                    data: feedback.user,
                    userType:feedback.user_type,
                    isVerified: feedback.is_verified

                }, 
                {
                    path: "/", // available accross this application
                    maxAge: 86400 //1 day
                }
            );
            navigate('/tutor/dashboard')
          
        } else {
            setAlertData({
                title: "oops!",
                message: feedback.error,
                color: "danger"
            })
            

        }
        
    }
    
    //  PASSWORD VISIBILITY TOGGLE 

    $('.cust-eye-span').on('click', function(event){
    
        let targetIcon = $('.password-reveal');
    
        let passwordInput = $('.password');
        console.log(passwordInput.attr('type'));
        if(passwordInput.attr('type')==='password'){
            targetIcon.removeClass('fa-eye-slash').addClass('fa-eye');
            passwordInput.attr('type', 'text');
            event.stopImmediatePropagation();
        }
        else{
            targetIcon.removeClass('fa-eye').addClass('fa-eye-slash');
            passwordInput.attr('type', 'password');
            event.stopImmediatePropagation();
        }
    })


   

    return ( 
        <>
        
            <CustomAlert  data={alertData}/>
           
            <form action="" className="px-3">
                
                <div className="row justify-content-center">
                    <div className="col-sm-3 col-md-4 col-lg-3">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control py-3 cust-form-control " 
                            placeholder="First Name"
                            onChange={handleChange} 
                            aria-label="Username"
                            name="firstName"
                            value={formData.firstName}
                            required />
                            <span className="input-group-text  " id="eye"><i className="fa fa-user" ></i></span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-md-4 col-lg-3">
                        <div className="input-group mb-3">
                            <input type="text" 
                            className="form-control py-3 cust-form-control" 
                            placeholder="Last Name"
                            onChange={handleChange} 
                            aria-label="Username"
                            name="lastName"
                            value={formData.lastName}
                            required />
                            <span className="input-group-text  " id="eye"><i className="fa fa-user-plus" ></i></span>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-6 col-md-8 col-lg-6">
                        <div className="input-group mb-3">
                            <input type="email" 
                            className="form-control py-3 cust-form-control" 
                            placeholder="Email Address"
                            onChange={handleChange} 
                            aria-label="Username"
                            value={formData.email}
                            name="email"
                            />
                            <span className="input-group-text  " id="eye"><i className="fa fa-envelope" ></i></span>
                        </div>
                    </div>
                </div> 
                <div className="row justify-content-center">
                    <div className=" col-sm-6 col-md-8 col-lg-6">
                        <div className="input-group mb-3">
                            <input type="password" 
                            id="passwordInput"
                            className="form-control py-3 cust-form-control password" 
                            placeholder="Password" 
                            onChange={handleChange}
                            aria-label="Username" 
                            value={formData.password}
                            name="password"
                            required />
                            <span className="input-group-text  cust-eye-span" id="eye"><i className="fa fa-eye-slash password-reveal" ></i></span>
                        </div>
                    </div>
                </div>   
                {/* <small className="form-check" htmlFor="flexCheckDefault">
                    By clicking, I agree to Stormcell's <Link to="#"><strong className="text-primary">Terms of Use Privacy Policy</strong></Link> and to receive promotional material from Stormcell.
                </small> */}
               
                <div className="col-12 text-center py-3">

                    <button type="button" className="academy-btn btn-secondary p-md-btn border-1 border-secondary" id="signUpBtn"  onClick={submitForm}>Register</button>
                </div>
            </form> 
        </>
    );
}
 
export default TutorRegisterForms;